import React, { useState } from 'react';

export default function useToken() {

  const getToken = () => {
    const tokenString = localStorage.getItem("authtoken");
    return tokenString;
  }

 const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("authtoken", userToken);
    setToken(userToken);
  }


  if(token){
    return [{
      setToken: saveToken,
      token:token}]
  }

  else{
    return [{}]
  }

}
