import React, { useState, useEffect } from "react";
import auth from "../../Auth/jwtMiddleware";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./AddBooking.css";
import loader from "../../assets/images/loader.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import copyImage from "../../assets/copy.svg";
import * as clipboard from "clipboard-polyfill";


const AddBooking = (data) => {
  const api = process.env.REACT_APP_PUBLIC_URL;

  // console.log(data?.Data?.maxforward);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [start, setStart] = useState(moment(data?.Data?.start).toDate());
  const [minstart] = useState(moment(data?.Data?.start).toDate());
  const [maxstart] = useState(moment(data?.Data?.startmax).toDate());
  const [end, setEnd] = useState(data?.Data?.end);
  const [note, setNotes] = useState("");
  const [field, setField] = useState(data?.Data?.fieldslug);
  const [service, setService] = useState(data?.Data?.servicename);
  const [serviceslug] = useState(data?.Data?.service);
  const [payment, setPayment] = useState("");
  const [quantity, setQuantity] = useState(10);
  const [duration, setDuration] = useState(data?.Data?.duration);
  const [displayduration, setDisplayDuration] = useState(
    data?.Data?.duration + " Minutes"
  );
  const [paid, setPaid] = useState("");
  const [bookingstatus] = useState(data?.Data?.bookingstatus);
  const [paymentstatus] = useState(data?.Data?.paymentstatus);
  const [paymenttype] = useState(data?.Data?.paymenttype);
  const [paymentt] = useState(data?.Data?.payment);
  const [birthdaycost] = useState(data?.Data?.birthdaycost);
  const [birthdayprice, setBirthdayPrice] = useState("");
  const [maxforward, setMaxForward] = useState(data?.Data?.maxforward);
  const [selectDuration, setSelectDuration] = useState(
    data?.Data?.maxforward[0]
  );

  // console.log(service)

  //
  //

  const [payment_type, setPayment_Type] = useState("");
  const [payment_status, setPayment_Status] = useState("");
  const [booking_status, setBooking_Status] = useState("");
  const [totalcost, setTotalCost] = useState(data?.Data?.pitchcost);
  const [pitchcost, setPitchcost] = useState(data?.Data?.pitchcost);
  const [birthdaytotalcost, setBirthdayTotalCost] = useState(
    data?.Data?.pitchcost
  );

  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [failuretext, setFailureText] = useState("");
  const [paymenturl, setPaymentUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [period, setPeriod] = useState("");
  const [length, setLength] = useState("");
  const [recurring, setRecurring] = useState(false);

  const [recurringData, setRecurringData] = useState([]);
  const [isloadingRecur, setIsLoadingRecur] = useState(false);

  const [running, setRunning] = useState(false);

  const [recurhasConflict, setRecuHasConflict] = useState(false);

  const min = 10;
  const max = 99999;

  // console.log(recurhasConflict)

  const initialDates = [
    { id: 0, name: "M", shouldBeChecked: false },
    { id: 1, name: "T", shouldBeChecked: false },
    { id: 2, name: "W", shouldBeChecked: false },
    { id: 3, name: "T", shouldBeChecked: false },
    { id: 4, name: "F", shouldBeChecked: false },
    { id: 5, name: "S", shouldBeChecked: false },
    { id: 6, name: "S", shouldBeChecked: false },
  ];

  //
  const [customdates, setCustomDates] = useState(initialDates);
  const [checkedDates, setCheckedDates] = useState([]);
  const [hascustomweek, setHasCustomWeek] = useState(false);

  useEffect(() => {
    // console.log(checkedDates)
    // console.log(' ')
  }, [checkedDates, customdates, period]);

  const handleCheckboxChange = (itemId) => {
    if (running === true) {
      return;
    }

    // else {
    setRunning(true);

    if (checkedDates.length > 0) {
      let items = checkedDates;

      let containsItem = checkedDates.includes(itemId);
      if (containsItem) {
        items.splice(itemId);
      } else {
        items.push(itemId);
      }
      setCheckedDates(items);
    } else {
      setCheckedDates([itemId]);
    }
    // console.log(checkedDates.length)
    // console.log(checkedDates)

    const newState = customdates.map((item) => {
      if (item.id === itemId) {
        return { ...item, shouldBeChecked: !item.shouldBeChecked };
      }
      return item;
    });

    setCustomDates(newState);
    setRunning(false);
    // }
  };

  // console.log(birthdaycost);

  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    data?.onValueChange(false);
    event.preventDefault();
  };

  const handleQuantity = (event) => {
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setQuantity(value);

    // console.log(birthdayprice)
    if (birthdayprice) {
      setBirthdayTotalCost(birthdayprice?.rate * value);
      handleRecurringQuantity(length, birthdayprice?.rate * value);
    } else {
      setBirthdayTotalCost(birthdaycost[0]?.rate * value);
      handleRecurringQuantity(length, birthdaycost[0]?.rate * value);
    }
  };

  //

  const handleRecurringQuantity = (qty, value) => {
    // console.log('lkdlskdsldk')
    if (serviceslug === "pitch-rental") {
      setTotalCost(Number(pitchcost) * (Number(qty) + 1));
      // console.log('coooooooost')
    } else {
      setTotalCost(Number(value) * (Number(qty) + 1));
    }
  };

  const handleCatering = (event) => {
    setBirthdayPrice(birthdaycost[event]);
    setTotalCost(birthdaycost[event]?.rate * quantity);
  };

  useEffect(() => {
    if (serviceslug === "birthday-party") {
      setBirthdayPrice(birthdaycost[0]);
      setTotalCost(birthdaycost[0]?.rate * quantity);
      setBirthdayTotalCost(birthdaycost[0]?.rate * quantity);
    }
  }, []);

  const saveBooking = async (e) => {
    e.preventDefault();
    let datasend = {};
    setLoading(true);

    let pay = paid;

    let recur = {};
    if (
      !recurhasConflict &&
      period &&
      length &&
      recurring &&
      hascustomweek &&
      checkedDates.length > 0
    ) {
      // var st = ""
      // const obj =  checkedDates.sort()
      recur = {
        type: period,
        length: length,
        days: checkedDates.sort().toString(),
        // days:checkedDates.sort().toString().replace("[",'').replace(']','')
      };
    } else if (!recurhasConflict && period && length && recurring) {
      recur = {
        type: period,
        length: length,
      };
    }

    if (pay === "") {
      pay = 0;
    }
    if (serviceslug === "pitch-rental") {
      if (payment === "on-site") {
        // console.log("onsite");
        datasend = {
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          duration: Number(duration),
          paid: pay,
          recurring: recur,
          booking_status_slug: booking_status,
          payment_status_slug: payment_status,
        };
      } else {
        // console.log('onsite else');

        datasend = {
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          duration: Number(duration),
          booking_status_slug: booking_status,
          payment_status_slug: payment_status,
          recurring: recur,
        };
      }
    } else {
      // console.log(" else");

      if (payment === "on-site") {
        // console.log("onsite b-th");
        datasend = {
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          duration: Number(duration),
          birthday: birthdayprice.slug,
          quantity: quantity,
          paid: pay,
          booking_status_slug: booking_status,
          payment_status_slug: payment_status,
          recurring: recur,
        };
      } else {
        // console.log("offsite b-th");
        datasend = {
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          quantity: quantity,
          duration: Number(duration),
          birthday: birthdayprice.slug,
          booking_status_slug: booking_status,
          payment_status_slug: payment_status,
          recurring: recur,
        };
      }
    }

    // console.log(datasend);

    try {
      setFailure(false);
      setSuccess(false);

      await auth()
        .post(`${api}/bookings/book`, datasend)
        .then(({ data }) => {
          // console.log(data);
          if (data.status === 200) {
            setSuccess(true);
            setMessage(data.message);
            // console.log(data.data)
            setPaymentUrl(data.data.payment_url);
          } 
          
          if(data.status === 200){

          }
          
          else {

            setFailureText(data.message);
            setFailure(true);
          }
          setLoading(false);
        });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      else{
        setFailureText(error);
        setFailure(true);
        setLoading(false);
      }

    }
  };

  const handleDuration = (data) => {
    setHasCustomWeek(false);
    setPeriod(data);
    let len = length;
    if (data === "weekly-custom") {
      setHasCustomWeek(true);
      // console.log(checkedDates.length);
      if (checkedDates.length > 0) {
        setLength(len);
        getRecurring(data, len);
        return;
      }

      return;
      // if(recurring)
    } else {
      // if (data === "monthly" && length > 2) {
      //   len = 2;
      // }

      setLength(len);
      getRecurring(data, len);
    }
  };

  const handleRecurring = () => {
    // console.log(recurring)
    if (recurring) {
      setRecuHasConflict(false);
    } else {
      setRecuHasConflict(true);
    }
    setRecurring(!recurring);
  };

  const handleLength = (data) => {
    let len = data;
    // if (period === "monthly" && data > 2) {
    //   len = 2;
    // }
    setLength(len);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getRecurring(period, length);
      if (birthdaycost) {
        handleRecurringQuantity(length, birthdaycost[0]?.rate * quantity);
      }
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [length]);

  const getRecurringAll = async () => {
    if (
      (period && length && recurring && !hascustomweek) ||
      (period &&
        length &&
        recurring &&
        hascustomweek &&
        checkedDates.length > 0)
    ) {
      setIsLoadingRecur(true);
      setRecuHasConflict(false);

      let urlobject = "";
      // console.log(hascustomweek)
      if (hascustomweek === true) {
        // console.log("hastocustom");
       
        urlobject = `/bookings/recurring?type=${period}&length=${length}&field=${field}&start=${moment(start).format("YYYY-MM-DD HH:mm:ss")}&end=${end}&days=${checkedDates.sort()}`;
      } else {
        urlobject = {
          type: period,
          length: length,
          start: start,
          end: end,
          field: "pitch-1",
        };
        // console.log("object");

        urlobject = `/bookings/recurring?type=${period}&length=${length}&field=${field}&start=${ moment(start).format("YYYY-MM-DD HH:mm:ss")}&end=${end}`;
      }

      // console.log(data)
      try {
        // setFailure(false);
        // setSuccess(false);
        setLoading(true);
        await auth()
          .get(`${api}` + urlobject)
          .then((data) => {
            // console.log(data);
            setLoading(false);
            if (data.status === 200) {
              if (Array.isArray(data?.data)) {
                setRecurringData(data?.data);
              } else if (typeof data?.data === "object") {
                if (Array.isArray(data?.data)) {
                  setRecurringData(data?.data);
                }
              }

              setRecurringData(data?.data);
              data?.data.forEach((element) => {
                if (element.booking !== false) {
                  setRecuHasConflict(true);
                }
                return;
              });
            }
            if (data.status === 401) {
              localStorage.clear();
              window.location.reload(false);
            } else {
            }
          });
      } catch (error) {
        // console.log(error);
        setLoading(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.reload(false);
        }
      }
    }
  };

  const getRecurring = async (perioddata, lengthdata) => {
    if (
      (perioddata && lengthdata && recurring && !hascustomweek) ||
      (perioddata &&
        lengthdata &&
        recurring &&
        hascustomweek &&
        checkedDates.length > 0)
    ) {
      setIsLoadingRecur(true);
      setRecuHasConflict(false);

      let urlobject = "";
      // console.log(hascustomweek)
      if (hascustomweek === true) {
        // console.log("hastocustom");
        urlobject = `/bookings/recurring?type=${perioddata}&length=${lengthdata}&field=${field}&start=${moment(start).format("YYYY-MM-DD HH:mm:ss")}&end=${end}&days=${checkedDates.sort()}`;
      } else {
        // urlobject = {
        //   type: perioddata,
        //   length: lengthdata,
        //   start: start,
        //   end: end,
        //   field: field,
        // };
        // console.log("object");

        urlobject = `/bookings/recurring?type=${perioddata}&length=${lengthdata}&field=${field}&start=${moment(start).format("YYYY-MM-DD HH:mm:ss")}&end=${end}`;
      }

      // console.log(data)
      try {
        // setFailure(false);
        // setSuccess(false);
        setLoading(true);
        await auth()
          .get(`${api}` + urlobject)
          .then((data) => {
            // console.log(data);
            setLoading(false);
            if (data.status === 200) {
              if (Array.isArray(data?.data)) {
                setRecurringData(data?.data);
              } else if (typeof data?.data === "object") {
                if (Array.isArray(data?.data)) {
                  setRecurringData(data?.data);
                }
              }

              setRecurringData(data?.data);
              data?.data.forEach((element) => {
                if (element.booking !== false) {
                  setRecuHasConflict(true);
                }
                return;
              });
            }
            if (data.status === 401) {
              localStorage.clear();
              window.location.reload(false);
            } else {
            }
          });
      } catch (error) {
        // console.log(error);
        setLoading(true);
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.reload(false);
        }
      }
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await clipboard.writeText(text);
      // console.log("Text copied to clipboard:", text);
      window.alert(`Payment Link copied`);
    } catch (error) {
      // console.error("Error copying to clipboard:", error);
      window.alert(`Error copying to clipboard: ${text}`);
    }
  };

  const handleDate = async (data) => {
    // console.log(moment(data).isSame(minstart));
    // console.log(moment(minstart).toDate());
    if(moment(data).isSame(minstart)){
      // console.log(maxforward[0]);
      setSelectDuration(maxforward[0]);
    }
    else{
      // console.log(maxforward[1]);
      //  console.log('2nd step')
      setSelectDuration(maxforward[1]);
    }
 
    setStart(data);
    setEnd(moment(data).add(duration, "minutes").format("YYYY-MM-DD HH:mm:ss"));
  };


  const handleDurationChange  = async (data) =>{
    setDuration(data)
    setEnd(moment(start).add(data, "minutes").format("YYYY-MM-DD HH:mm:ss"));
  }

  // const changePayment = (e) => {};

  return (
    <>
      {loading ? (
        <>
          <div id="loader">
            <img src={loader} width="100" alt="Loader" />
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="row mt-3 mb-3  d-flex justify-content-center align-items-center ">
        <div className="col-md-12">
          <div
            className="modal "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <form onSubmit={saveBooking}>
                  <div className="modal-header">
                    <h5 className="modal-title">Add New Booking</h5>
                    <button
                      type="button"
                      className="close"
                      value={inputValue}
                      onClick={handleChange}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  {/*  */}

                  <div className="modal-header">
                    <div className="modal-body gap-3">
                      <p className="text-muted mb-2 fs-18">
                        Enter the details of the customer
                      </p>
                      <input
                        className="form-control mb-2"
                        placeholder="Enter Full Name"
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      ></input>
                      <input
                        className="form-control mb-2"
                        placeholder="Enter Email ID"
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      ></input>
                      <input
                        className="form-control mb-2"
                        placeholder="Enter Phone Number"
                        type="text"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="modal-body gap-3">
                    <p className="text-muted mb-2 fs-18">Enter Booking</p>
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          className="form-control mb-2"
                          placeholder="Title"
                          type="text"
                          id="phone"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        ></input>
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          className="form-control mb-2 col-md-6"
                          showIcon
                          id="start"
                          selected={start}
                          onChange={(date) => handleDate(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-d HH:mm"
                          // timeIntervals={data?.Data?.duration}
                          minDate={new Date()}
                          maxDate={new Date()}
                          //
                          minTime={minstart}
                          maxTime={maxstart}
                          required
                        />
                        {/* <input
                          className="form-control mb-2 col-md-6"
                          placeholder="Start Time"
                          type="text"
                          id="start"
                          value={start}
                          onChange={(e) => setStart(e.target.value)}
                          required
                          readOnly
                        ></input> */}
                      </div>
                      <div className="col-md-6">
                        <input
                          className="form-control mb-2 col-md-6"
                          placeholder="End Time"
                          type="text"
                          id="text"
                          value={end}
                          onChange={(e) => setEnd(e.target.value)}
                          required
                          readOnly
                        ></input>
                      </div>

                      <div className="col-md-6">
                        <input
                          className="form-control mb-2 col-md-6"
                          placeholder="Field"
                          type="text"
                          id="field"
                          value={field}
                          onChange={(e) => setField(e.target.value)}
                          required
                          readOnly
                        ></input>
                      </div>

                      <div className="col-md-6">
                        <input
                          className="form-control mb-2 col-md-6"
                          placeholder="Service"
                          type="text"
                          id="service"
                          value={service}
                          onChange={(e) => setService(e.target.value)}
                          required
                          readOnly
                        ></input>
                      </div>

                      <div className="col-md-6">
                        {serviceslug === "pitch-rental" ? (
                          // console.log(service),
                          <>
                            <select
                              className="form-control mb-2 col-md-12"
                              onChange={(e) => handleDurationChange(e.target.value)}
                              value={duration}
                              required
                            >
                              <option value="" disabled>
                                Duration
                              </option>
                              {Object.entries(selectDuration).map(
                                ([durationId, duration]) => (
                                  <option
                                    value={duration}
                                    key={`duration-id-` + durationId}
                                  >
                                    {duration} Minutes
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        ) : (
                          <>
                            <input
                              className="form-control mb-2 col-md-6"
                              placeholder="Duration"
                              type="text"
                              id="duration"
                              value={displayduration}
                              onChange={(e) => setDuration(e.target.value)}
                              required
                              readOnly
                            ></input>
                          </>
                        )}
                      </div>
                      {serviceslug === "birthday-party" ? (
                        <>
                          <div className="col-md-6">
                            <input
                              className="form-control mb-2 col-md-6"
                              placeholder="Quantity"
                              type="number"
                              id="quantity"
                              min="10"
                              value={quantity}
                              // onChange={(e) => setQuantity(e.target.value)}
                              onChange={handleQuantity}
                            ></input>
                          </div>
                          <div className="col-md-12">
                            <select
                              className="form-control mb-2 col-md-12"
                              // value={birthdayprice}
                              onChange={(e) => handleCatering(e.target.value)}
                              required
                            >
                              <option value="" disabled>
                                Catering Type
                              </option>
                              {Object.entries(birthdaycost).map(
                                ([fieldId, booking]) => (
                                  <option
                                    value={fieldId}
                                    key={`booking-cost-` + fieldId}
                                  >
                                    {booking.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="col-md-12">
                        <select
                          className="form-control mb-2 col-md-12"
                          value={booking_status}
                          onChange={(e) => setBooking_Status(e.target.value)}
                          required
                        >
                          <option value="">Booking Status</option>
                          {Object.entries(bookingstatus).map(
                            ([fieldId, booking]) => (
                              <option
                                value={booking?.slug}
                                key={`booking-` + fieldId}
                              >
                                {booking?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="col-md-12">
                        <select
                          className="form-control mb-2 col-md-12"
                          value={payment_status}
                          onChange={(e) => setPayment_Status(e.target.value)}
                          required
                        >
                          <option value="">Payment Status</option>
                          {Object.entries(paymentstatus).map(
                            ([fieldId, payment]) => (
                              <option
                                value={payment?.slug}
                                key={`paymentstatus-` + fieldId}
                              >
                                {" "}
                                {payment?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="col-md-12">
                        <select
                          className="form-control mb-2 col-md-12"
                          value={payment_type}
                          onChange={(e) => setPayment_Type(e.target.value)}
                          required
                        >
                          <option value="">Payment Type</option>
                          {Object.entries(paymenttype).map(
                            ([fieldId, payment]) => (
                              <option
                                value={payment?.slug}
                                key={`paymenttype-` + fieldId}
                              >
                                {" "}
                                {payment?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="col-md-12">
                        <select
                          className="form-control mb-2 col-md-12"
                          value={payment}
                          onChange={(e) => setPayment(e.target.value)}
                          required
                        >
                          <option value="">Payment</option>
                          {Object.entries(paymentt).map(
                            ([fieldId, payment]) => (
                              <option
                                value={payment.slug}
                                key={`payment-` + fieldId}
                              >
                                {" "}
                                {payment?.name}
                              </option>
                            )
                          )}
                        </select>

                        {payment === "on-site" ? (
                          <input
                            className="form-control mb-2 col-md-6"
                            placeholder="Amount Paid"
                            type="number"
                            id="amount"
                            value={paid}
                            onChange={(e) => setPaid(e.target.value)}
                          ></input>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="col-md-12">
                        <textarea
                          className="form-control mb-2 col-md-6"
                          placeholder="Notes"
                          type="text"
                          id="note"
                          value={note}
                          onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  {serviceslug !== "birthday-party" ? (
                    <>
                      <div className="modal-body gap-3">
                        <label className="recurringLabel mb-2">
                          Recurring:{" "}
                          <input
                            type="checkbox"
                            id="recurring"
                            className="recurringcheckbox"
                            name="recurring"
                            // value={recurring}
                            onChange={handleRecurring}
                            checked={recurring}
                          />
                        </label>
                        {/*  */}

                        {recurring ? (
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <select
                                  className="form-control mb-2 col-md-6"
                                  id="field-profile-type"
                                  name="profile_type"
                                  onChange={(e) =>
                                    handleDuration(e.target.value)
                                  }
                                >
                                  <option key="plaeholder" value="">
                                    {" "}
                                    Select Type
                                  </option>
                                  <option key="daily" value="daily">
                                    Daily
                                  </option>
                                  <option key="weekly" value="weekly">
                                    Weekly
                                  </option>
                                  <option key="monthly" value="monthly">
                                    Monthly
                                  </option>
                                  <option
                                    key="custom-weekly"
                                    value="weekly-custom"
                                  >
                                    Custom Weekly
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-6">
                                <input
                                  className="form-control mb-2 col-md-6"
                                  placeholder="# of Consecutive Days/Weeks/Months"
                                  type="number"
                                  id="lenghth"
                                  value={length}
                                  onChange={(e) => handleLength(e.target.value)}
                                ></input>
                              </div>
                            </div>
                            {hascustomweek ? (
                              <div className="custom-weekly-items">
                                {customdates.map((item) => (
                                  <div
                                    key={item.id}
                                    className={
                                      item.shouldBeChecked
                                        ? "checked d-inline-flex custom-week"
                                        : "d-inline-flex custom-week"
                                    }
                                    onClick={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  >
                                    {item.name}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}

                            <button
                              type="button"
                              className="btn btn-secondary mr-2"
                              value={inputValue}
                              onClick={getRecurringAll}
                            >
                              Get Recurring Booking
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {recurring ? (
                    <div className="col-md-12 modal-body gap-3">
                      <table className="table table-hover table-responsive">
                        <thead className="bg-black text-white">
                          <tr className="">
                            <td>Date</td>
                            <td>Start</td>
                            <td>End</td>
                            <td>Availability</td>
                          </tr>
                        </thead>
                        <tbody>
                          {recurringData?.map((data, id) => (
                            // console.log(data),

                            <tr
                              key={"recurring-" + id}
                              className={
                                data.booking === false ? "" : "unavailable"
                              }
                            >
                              <td>{moment(data.start).format("YYYY-MM-DD")}</td>
                              <td>{moment(data.start).format("hh:mm")}</td>
                              <td>{moment(data.end).format("hh:mm")}</td>
                              <td>
                                {data.booking === false ? (
                                  <> Available</>
                                ) : (
                                  <>Unavailable</>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <></>
                  )}

                  {success ? (
                    <>
                      <div className="modal-body gap-3"></div>
                      <div className="modal-footer payment-url text-bg-success">
                        <p className=" text-white mb-2  mb-2 fs-18">
                          {" "}
                          {message}
                        </p>

                        <p
                          className="pay-url  text-white mb-2 fs-18"
                          onClick={() => copyToClipboard(paymenturl)}
                        >
                          Payment Url{" "}
                          <img
                            src={copyImage}
                            height="20px"
                            className="ps-3"
                            alt="copy "
                          />
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {failure ? (
                    <>
                      <div className="modal-body gap-3"></div>
                      <div className="modal-footer payment-url text-bg-warning">
                        <p className="text-white mb-2 fs-18">Could Not Book</p>
                        <p className="text-white">{failuretext}</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* {recuconflictDisplay?(
                    <>
                          <div className="modal-body gap-3"></div>
                         <div className="modal-footer payment-url text-bg-warning">
                         <p className="text-white">There are conflicts found; please select dates/time without conflicts</p>
                         </div>
                    </>
                  ):(<></>)}  */}

                  <div className="modal-body gap-3"></div>
                  <div className="modal-footer">
                    <div>
                      <h5 className="modal-title">AED {totalcost}</h5>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-secondary mr-2 me-2"
                        value={inputValue}
                        onClick={handleChange}
                      >
                        Close
                      </button>
                      {recurhasConflict ? (
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled
                        >
                          Save
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-success">
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBooking;
