import React, { useEffect, useState } from "react";
import auth from "../../Auth/jwtMiddleware";
import "./Bookings.css";
// import AddCustomer from '../components/AddCustomer/AddCustomer';
// import EditCustomer1 from '../components/EditCustomer/EditCustomer1';
import loader from "../../assets/images/loader.svg";
import moment from "moment";
import { faSmileBeam } from "@fortawesome/free-solid-svg-icons";
import UpdateBooking from "../UpdateBooking/UpdateBooking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import loader from "../assets";

const api = process.env.REACT_APP_PUBLIC_URL;

const Customer = () => {
  const [data, setData] = useState([]);
  const [successMessage, setShowMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceloader, setServiceLoader] = useState(false);
  const [servicedata, setServiceData] = useState([]);
  const [service, setService] = useState([]);
  const [statuseloader, setStatuses] = useState(false);
  //
  const [bookingstatus, setBookingStatus] = useState([]);
  const [paymentstatus, setPaymentStatus] = useState([]);
  const [paymenttype, setPaymentType] = useState([]);
  const [payment, setPayment] = useState([]);
  const [paymentt] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [bookingPages, setBookingPages] = useState(0);
  const [nodata, setNoData] = useState(false);
  const [booksItems, setBooksItems] = useState(0)

  //
  const [bookingitems, setBookingItems] = useState("");
  const [paymentstatusitems, setPaymentStatusItems] = useState("");
  const [paymentTypeitems, setPaymentTypeItems] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedupdatebooking, setSelectedUpdateBooking] = useState([]);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [date, setDate] = useState(new Date());
  const [duration, setDuration] = useState(60);
  const [isbirthday, setIsBirthday] = useState(false);
  const [bookingloader, setBookingTime] = useState(false);
  const [pitchdata, setPitchData] = useState([]);
  const [daterange,setDateRange] = useState("");

  // const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // eslint-disable-next-line
  useEffect(() => {
    // getBookings();
    getServices();
    // editCustomers();
  }, []);

  const getBookings = async () => {
    setLoading(true);
    try {
      await auth()
        .get(`${api}/bookings/filter`)
        .then((res) => {
          // console.log(res.data.items);
          if (Array.isArray(res?.data?.items)) {
            setData(res?.data?.data);
          } else if (typeof res?.data?.items === "object") {
            if (Array.isArray(res?.data?.items)) {
              setData(res?.data?.items);
            }
          }
          setData(res?.data?.items);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setShowMessage("We Encountered an Error Please Try again Later");

      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      // console.error('Error fetching data:', error);
    }
  };
  const BookingsDelete = async (id) => {
    //   setLoading(true);
    //   try {
    //     await auth().delete(`${api}/customers/delete?id=${id}`).then(() => {
    //       setShowMessage('Customer Deleted Successfully');
    //       setTimeout(() => {
    //         setShowMessage(false);
    //         window.location.reload(); // Reload the page after 2 seconds
    //       }, 2000);
    //     })
    //     .catch(error => {
    //       setShowMessage('We Encountered an Error Please Try again Later');
    //       // console.log(error);
    //     });
    //     setLoading(false);
    //   } catch (error) {
    //     setShowMessage('We Encountered an Error Please Try again Later');
    //     // console.error('Error deleting row:', error);
    //     setLoading(false);
    //   }
  };

  const getServices = async () => {
    setServiceLoader(true);
    try {
      await auth()
        .get(`${api}/services/get`)
        .then(({ data }) => {
          if (data?.status === 200);
          setServiceData(data?.data);

          setService(data?.data?.[0]);
          // getBookingTime(data?.data?.[0]);
          getStatuses();
          setServiceLoader(false);

          //if(data?.data)
        });
    } catch (error) {
      setServiceLoader(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  async function getStatuses() {
    setStatuses(true);
    try {
      await auth()
        .get(`${api}/bookings/get-statuses`)
        .then(({ data }) => {
          setBookingStatus(data?.booking_statuses);
          setPaymentStatus(data?.payment_statuses);
          setPaymentType(data?.payment_type);
          setPayment(data?.payments);
          // setPitchData(data.data[0]);
        });
    } catch(error) {
      setStatuses(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  }

  function editCustomer() {
    // console.log("edit");
  }

  async function setBooking_Status(data) {
    // filterBookings(data);
    setBookingItems(data);
    filterBookings(
      data,
      paymentstatusitems,
      paymentTypeitems,
      searchText,
      pageNumber,
      daterange
    );
  }

  function setPayment_Status(data) {
    setPaymentStatusItems(data);
    filterBookings(
      bookingitems,
      data,
      paymentTypeitems,
      searchText,
      pageNumber,
      daterange
    );
  }

  function setPayment_Type(data) {
    setPaymentTypeItems(data);
    filterBookings(
      bookingitems,
      paymentstatusitems,
      data,
      searchText,
      pageNumber,
      daterange
    );
  }

  function handleDuration() {}

  function SearchText(data) {
    setSearchText(data);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      filterBookings(
        bookingitems,
        paymentstatusitems,
        paymentTypeitems,
        searchText,
        pageNumber,
        daterange
      );
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [searchText]);

  const handleValueUpdate = (value) => {
    // window.
    // getBookingTime(service)
    setShowModalUpdate(value);
  };

  async function filterBookings(
    booking_status,
    payment_status,
    payment_type,
    search_text,
    page,
    date
  ) {
    // console.log(booking_status);
    // console.log(booking_status);
    // console.log(payment_status);
    // console.log(payment_type);
    // console.log(date)

    let search = ``;

    let searchdata ={}
    if (booking_status !== "" && booking_status !== undefined) {
      search += "booking_status_id=" + booking_status + "&";
      searchdata.booking_status_id =booking_status;
    }
    if (payment_status !== "" && payment_status !== undefined) {
      searchdata.payment_status_id =payment_status;
      search += "payment_status_id=" + payment_status + "&";
    }
    if (payment_type !== "" && payment_type !== undefined) {
      searchdata.payment_type_id=payment_type
        
      
      search += "payment_type_id=" + payment_type + "&";
    }
    if (search_text !== "" && search_text !== undefined) {
      searchdata.search=search_text
      
      search += "search=" + search_text + "&";
    }
    if(date!==""){
      searchdata.date_range=date

      // search += 'date_range'+date + "&";
    }
    search += "page=" + page;
    searchdata.page=page
    
    // console.log(searchdata);
    // if()
    // search =+'booking_status_id='+booking_status

    


    try {
      setLoading(true);
      setNoData(false);
      setShowMessage("");
      await auth()
        .post(`${api}/bookings/filter`,searchdata)
        .then((res) => {
          setLoading(false);
          setData([]);
          // console.log(res)
          if (res?.status === 200) {
            // console.log(res?.data);
            // console.log(res?.data?.pages);

            setBookingPages(res?.data?.pages);
            setBooksItems(res?.data?.total)

            if (Array.isArray(res?.data?.items)) {
              setData(res?.data?.items);
            } else if (typeof res?.data?.items === "object") {
              if (Array.isArray(res?.data?.items)) {
                setData(res?.data?.items);
              }
            }
            setData(res?.data?.items);
            if (res?.data?.items.length <= 0) {
              setNoData(true);
            }
            setLoading(false);
          }
        });
    } catch (error) {
      // console.log(error)

      setLoading(false);
      setShowMessage("We Encountered an Error Please Try again Later");
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }

    // console.log(data)
  }

  async function editBooking(data) {
    // console.log(data)
    setLoading(true);
    try {
      await auth()
        .post(`${api}/bookings/find?id=${data}`)
        .then((res) => {
          // console.log(res?.data);
          if (res?.data?.status === 200) {
            // console.log(res?.data?.data[0])
            let dat1 = res?.data.data;
            // console.log(dat1);
            setLoading(false);
            updateBookingModal(dat1);
          }
        })
        .catch((e) => {
          // console.log(e);
          // localStorage.clear();
          // window.location.reload(false);
          if (e?.response?.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          }
        });
    } catch (error) {
      setLoading(false);
      setShowMessage("We Encountered an Error Please Try again Later");
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  }

  function updateBookingModal(booking) {
    // console.log(booking);
    let hasrecurring = false;
    let recurringlength = 0;
    let recurringdata = [];
    let recurringtype = "";
    let recurringdays = [];
    if (booking?.recurring?.data?.length > 0 || booking?.recurring?.length > 0) {
      // console.log(booking?.booking[0]?.recurring)
      hasrecurring = true;
      recurringlength = booking?.recurring?.length;
      recurringdata = booking?.recurring?.data;
      recurringtype = booking?.recurring?.type;
      recurringdays = JSON.parse(booking?.recurring?.days);
    }

    let newdata = {};

    if (service.slug === "pitch-rental") {
      // console.log("pitch");
      let serv = [];
      servicedata.forEach((element) => {
        if (element.slug === "birthday-party") {
          serv = element.extras;
        }
      });
      newdata = {
        id: booking?.id,
        duration: booking?.duration,
        service: booking?.service.slug,
        fieldslug: booking?.field_slug,
        start: booking?.start,
        end: booking?.end,
        servicename: booking?.service?.name,
        bookingstatus: bookingstatus,
        booking_status_slug: booking?.booking_status_slug,
        paymentstatus: paymentstatus,
        payment_status_slug: booking?.payment_status_slug,
        paymenttype: paymenttype,
        payment_type_slug: booking?.payment_type_slug,
        payment: payment,
        // pitchcost: pitchcost[alldata.slug][duration],
        payment_slug: booking?.payment_slug,
        customer: booking?.customer,
        title: booking?.title,
        total: booking?.total,
        notes: booking?.notes,
        birthdaycost: serv,
        paid: booking?.paid,
        payment_url: booking?.payment_url,
        birthday_extra_service: booking?.service_extra_slug,
        qty: booking?.qty,
        hasrecurring: hasrecurring,
        recurringlength: recurringlength,
        recurringdata: recurringdata,
        recurringtype: recurringtype,
        recurringdays: recurringdays,
        overallpaid:booking?.overall_paid,
        overalltotal:booking?.overall_total,
      };
    } else {
      let serv = [];
      servicedata.forEach((element) => {
        if (element.slug === "birthday-party") {
          serv = element.extras;
        }
      });

      newdata = {
        id: booking.id,
        duration: booking?.duration,
        service: booking?.service.slug,
        fieldslug: booking?.field_slug,
        start: booking?.start,
        end: booking?.end,
        servicename: booking?.service?.name,
        bookingstatus: bookingstatus,
        booking_status_slug: booking?.booking_status_slug,
        paymentstatus: paymentstatus,
        payment_status_slug: booking?.payment_status_slug,
        paymenttype: paymenttype,
        payment_type_slug: booking?.payment_type_slug,
        payment: payment,
        payment_slug: booking?.payment_slug,
        customer: booking?.customer,
        title: booking?.title,
        birthdaycost: serv,
        // pitchcost: pitchcost[alldata.slug]["120"],
        total: booking?.total,
        notes: booking?.notes,
        paid: booking?.paid,
        qty: booking?.qty,
        payment_url: booking?.payment_url,
        birthday_extra_service: booking?.service_extra_slug,
        hasrecurring: hasrecurring,
        recurringlength: recurringlength,
        recurringdata: recurringdata,
        recurringtype: recurringtype,
        recurringdays: recurringdays,
        overallpaid:booking?.overall_paid,
        overalltotal:booking?.overall_total,
      };
    }

    // console.log(newdata)
    setSelectedUpdateBooking(newdata);
    setShowModalUpdate(!showModalUpdate);
  }

  async function getBookingTime(serv, time) {
    let dte = moment(date).format("YYYY-MM-DD");
    // console.log(dte);
    var url = "get-pitch-rental-timeslots";
    let dur = 0;
    if (serv.slug === "pitch-rental") {
      url = "get-pitch-rental-timeslots";
      if (time === undefined) {
        if (duration === 0) {
          dur = "duration=" + 60;
        } else {
          dur = "duration=" + duration;
        }
      } else {
        dur = "duration=" + time;
      }
      setIsBirthday(false);
    } else {
      url = "get-all-birthday-timeslots";
      dur = "duration=120";
      setIsBirthday(true);
    }

    try {
      setBookingTime(true);
      await auth()
        .post(`${api}/timeslots/${url}?${dur}&date=${dte}`)
        .then(({ data }) => {
          // console.log(data);
          if (data.status == 200) {
            setPitchData(data.data[0]);
          } else {
            // console.log(data);
          }

          setBookingTime(false);
        });
    } catch (error) {
      setBookingTime(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  }

  function nextButton() {
    // console.log('next button');
    // console.log(pageNumber)
    filterBookings(
      bookingitems,
      paymentstatusitems,
      paymentTypeitems,
      searchText,
      pageNumber + 1,
      daterange
    );
    setPageNumber(pageNumber + 1);
  }

  function prevButton() {
    // console.log('prev button');
    filterBookings(
      bookingitems,
      paymentstatusitems,
      paymentTypeitems,
      searchText,
      pageNumber - 1,
      daterange

    );
    setPageNumber(pageNumber - 1);
  }

  const handleDatePicker = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    // console.log(range)
   

    let date=''

    // console.log(range)
    if (range[0]!==null && range[1]!==null){
      // console.log('two')
       date={
        "start": moment(startDate).format("YYYY-MM-DD"),
        "end":moment(endDate).format("YYYY-MM-DD")
      }
      setDateRange(date)
       filterBookings(bookingitems,paymentstatusitems,paymentTypeitems,searchText,pageNumber,date)
      // return
    }
    else if (range[0]===null || range[1]===null){
      // console.log('one')
      // console.log(range)
      setDateRange(date)
      // filterBookings(bookingitems,paymentstatusitems,paymentTypeitems,searchText,pageNumber,date)

      // date={
      //   "start": moment(startDate).format("YYYY-MM-DD"),
      // }
      // return

    }
   else if(range[1] !==null){
      // date={
      //   "start": moment(endDate).format("YYYY-MM-DD"),
      // }
      // return

    }
    else{
      date=""
      // console.log('else')
      setDateRange(date)
      // filterBookings(bookingitems,paymentstatusitems,paymentTypeitems,searchText,pageNumber,date)
      // return

    }
    // setDateRange(date)
    // console.log()
    // console.log(date)
  }

  function reset (){
    setBookingItems('')
    setPaymentStatusItems('')
    setPaymentTypeItems('')
    setSearchText('')
    setPageNumber(1)
    setDateRange('')
    // bookingitems,
    // paymentstatusitems,
    // paymentTypeitems,
    // searchText,
    // pageNumber,
    // daterange

    filterBookings('','','','','','');
  }
  

  return (
    <>
      <>
        {loading ? (
          <div id="loader">
            <img src={loader} width="100" alt="Loader" />
          </div>
        ) : (
          <></>
        )}
      </>

      <div className="row">
        <div className="col-md-12 mt-3 mb-3">
          <h1 className="text-center py-2 mb-3 font-weight-700">
            All <span className="calendar-text">Bookings</span>
          </h1>
        </div>
        <div className=" row pull-right mb-4 margin-inherit">
          <div className="col">
            <h4>Filters</h4>
          </div>
          <div className="col">
            <select
              className="form-control mb-2 col-md-12"
              value={bookingitems}
              onChange={(e) => setBooking_Status(e.target.value)}
              // required
            >
              <option value="">Booking Status</option>
              {Object.entries(bookingstatus).map(([fieldId, booking]) => (
                <option value={booking?.id} key={`booking-` + fieldId}>
                  {booking?.name}
                </option>
              ))}
            </select>
          </div>

          {/*  */}

          <div className="col">
            <select
              className="form-control mb-2 col-md-12"
              value={paymentstatusitems}
              onChange={(e) => setPayment_Status(e.target.value)}
              required
            >
              <option value="">Payment Status</option>
              {Object.entries(paymentstatus).map(([fieldId, payment]) => (
                <option value={payment?.id} key={`paymentstatus-` + fieldId}>
                  {" "}
                  {payment?.name}
                </option>
              ))}
            </select>
          </div>

          {/*  */}

          <div className="col">
            <select
              className="form-control mb-2 col-md-12"
              // value={payment_type}
              onChange={(e) => setPayment_Type(e.target.value)}
              required
            >
              <option value="">Payment Type</option>
              {Object.entries(paymenttype).map(([fieldId, payment]) => (
                <option value={payment?.id} key={`paymenttype-` + fieldId}>
                  {" "}
                  {payment?.name}
                </option>
              ))}
            </select>
          </div>

          {/*  */}

          {/* <div className="col">
            <select
              className="form-control mb-2 col-md-12"
              value={payment}
              onChange={(e) => setPayment(e.target.value)}
              required
            >
              <option value="">Payment Method </option>
              {Object.entries(paymentt).map(([fieldId, payment]) => (
                <option value={payment.slug} key={`payment-` + fieldId}>
                  {" "}
                  {payment?.name}
                </option>
              ))}
            </select>
          </div> */}

          {/*  */}

          <div className="col">
            <DatePicker
              showIcon
              className="form-control mb-2 col-md-6"
              selected={startDate}
              onChange={handleDatePicker}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Select Date..."
            />
          </div>
          <div className="col">
            <input
              className="form-control mb-2"
              placeholder="Search Text"
              type="text"
              id="name"
              value={searchText}
              onChange={(e) => SearchText(e.target.value)}
              required
            ></input>
          </div>
          <div className="col">
          <button className="btn btn-info" onClick={reset}> Reset</button>
          </div>

          {/* <div>
            {successMessage && (
              <div className="rowdelete mt-3">{successMessage}</div>
            )}
          </div> */}
        </div>
        <table className="table table-hover table-responsive">
          <thead className="bg-black text-white">
            <tr className="">
              {/* <td>#</td> */}
              {/* <td>Name</td> */}
              <td>Customer</td>
              {/* <td>Phone Number</td> */}
              <td>Details</td>
              {/* <td>Service</td> */}
              <td>Time</td>
              {/* <td>Date</td> */}
              {/* <td>Start</td> */}
              <td>Payment Status</td>
              <td>Payment Type</td>
              <td>Paid</td>
              <td>Cost</td>
              {/* <td>Recurring</td> */}

              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {data.map((data) => (
              // console.log(data),
              <tr key={data.id}>
                {/* <td>{data.id}</td> */}
                {/* <td>{data.customer?.name}</td> */}
                <td>
                  <p>
                <b>{data.customer?.name}</b>
              
                <br />
                  {data.customer?.email}
                  <br />
                {data.customer?.phone}
                <br />
                {/* {data.customer?.id} */}
                </p>
                
                </td>
               
                {/* <td>{data.customer?.phone}</td> */}
                <td>
                <b>{data.service?.name}</b>
                <br />
                 ( {data.field?.name})
               
                
                </td>
                {/* <td></td> */}
                <td>
                <b>{moment(data.start).format("DD MMM-YYYY")}</b>
                <br />
                {moment(data.start).format("HH:mm")} - {moment(data.end).format("HH:mm")}
                <br />
                 ({data.duration} Minutes)
                  </td>


                <td>{data.payment_status?.name}</td>
                <td>{data.payment_type?.name}</td>
                <td>AED {data.paid}</td>
                <td>AED {data.total}</td>
                {/* <td>{data.total}</td> */}

                {/* <td>Phone Number</td> */}

                {/* <td className=" d-flex justify-content-around align-items-center text-center"> */}
                <td className=" ">
                  <button
                    className="btn btn-edit"
                    onClick={() => editBooking(data.id)}
                    type="button"
                  >
                    Edit
                  </button>
                  {/* <EditCustomer1 data={data}/> */}
                  <button
                    className="btn btn-delete ms-2"
                    type="button"
                    onClick={() => BookingsDelete(data.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}

            {nodata ? (
              <tr className=" border-0">
                <td className=" border-0">
                  <h1 className="  mt-3 text-center w-auto text-center py-2 mb-3 font-weight-700">
                    No Items Found
                  </h1>
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <div className=" mb-5 pb-5 pt-2 mt-4 d-flex justify-content-evenly">
          {!nodata ? (
            <>
              {pageNumber <= 1 ? (
                <button className="btn btn-dark" disabled>
                  &#8249;
                </button>
              ) : (
                <button className="btn btn-dark" onClick={prevButton}>
                  &#8249;
                </button>
              )}
                <p className=" ">
                 <span className="font-weight-600">{pageNumber} / </span>  <span className="font-weight-600">{bookingPages}</span> <span className=" ms-5">{booksItems} bookings</span>
                </p>
              {pageNumber >= bookingPages ? (
                <button className="btn btn-dark" disabled>
                  {" "}
                  &#8250;
                </button>
              ) : (
                <button className="btn btn-dark" onClick={nextButton}>
                  {" "}
                  &#8250;
                </button>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showModalUpdate && (
        <UpdateBooking
          Data={selectedupdatebooking}
          onValueChange={handleValueUpdate}
        />
      )}
    </>
  );
};
export default Customer;
