import React, { useEffect, useState } from "react";
import auth from "../../Auth/jwtMiddleware";
import "./Customer.css";
import AddCustomer from "../AddCustomer/AddCustomer";
import EditCustomer1 from "../EditCustomer/EditCustomer1";
// import loader from "../assets/images/loader.svg";
import loader from "../../assets/images/loader.svg"
// import loader from "../assets";

const api = process.env.REACT_APP_PUBLIC_URL;

const Customer = () => {
  const [data, setData] = useState([]);
  const [successMessage, setShowMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [bookingPages, setBookingPages] = useState(0);
  const [nodata, setNoData] = useState(false);
  const [booksItems, setBooksItems] = useState(0);
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line
  useEffect(() => {
    // getCustomers();
    // editCustomers();
  }, []);

  const getCustomers = async (page,search_text) => {
    setLoading(true);
    setNoData(false);
    setShowMessage("")
    let search = ''
    if(page!==undefined && page!==""){
    search += 'page=' +page+ "&"
    }
    if(search_text!==undefined && search_text !==""){
      search +='search=' +search_text;
    }

    try {
      await auth()
        .post(`${api}/customers/search?${search}`)
        .then((res) => {

          // console.log(res);
          // console.log(res?.data?.data.length);
          // console.log(res?.data);

          setBookingPages(res?.data?.pages);
          setBooksItems(res?.data?.total)
          if(res?.status===200){
          if (Array.isArray(res?.data?.items)) {
            setData(res?.data?.items);
          } else if (typeof res?.data?.items === "object") {
            if (Array.isArray(res?.data?.items)) {
              setData(res?.data?.items);
            }
          }
          // console.log('kjdshdkjsahdjkhsajkdhsadkjsahdksajdyhsakudhk')
          // setData(res?.data?.items);
          if(res?.data?.items.length<=0){
            // console.log('0')
            setNoData(true);
          }
        }
          setLoading(false);
        });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      setLoading(false);
      setShowMessage("We Encountered an Error Please Try again Later");
      // console.error('Error fetching data:', error);
    }
  };

  const CustomerDelete = async (id) => {
    setLoading(true);
    setShowMessage("")
    try {
      await auth()
        .delete(`${api}/customers/delete?id=${id}`)
        .then(() => {
          if (data.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          }
          setShowMessage("Customer Deleted Successfully");
          setTimeout(() => {
            setShowMessage(false);
            window.location.reload(); // Reload the page after 2 seconds
          }, 2000);
        })
        .catch((error) => {
          setShowMessage("We Encountered an Error Please Try again Later");
          // console.log(error);
        });
      setLoading(false);
    } catch (error) {
      setShowMessage("We Encountered an Error Please Try again Later");
      // console.error('Error deleting row:', error);
      setLoading(false);
      if (error?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };


  const ViewBooking = async (id) =>{
    // console.log(id)
    // setLoading(true);
    let url = '/customerbooking/'+id;
    window.location.href = url
    // setShowMessage("")
    
  }

  function editCustomer() {
    // console.log('edit')
  }

  function prevButton() {
    getCustomers(pageNumber - 1)
    setPageNumber(pageNumber - 1)
  }

  function nextButton() {
    getCustomers(pageNumber + 1);
    setPageNumber(pageNumber + 1);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getCustomers(
        pageNumber,
        searchText
      );
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [searchText]);

  function SearchText(data) {
    setSearchText(data);
  }

  return (
    <>
      <>
        {loading ? (
          <div id="loader">
            <img src={loader} width="100" alt="Loader" />
          </div>
        ) : (
          <></>
        )}
      </>

      <div className="row">
        <div className="col-md-12 mt-3 mb-3">
          <h1 className="text-center py-2 mb-3 font-weight-700">
            All <span className="calendar-text">Customers</span>
          </h1>
        </div>
        <div className="row pull-right   mb-3 margin-inherit ">
          <div className="col">
            <AddCustomer />
          </div>
          <div className="col">
            <div className="row mt-3 mb-3  d-flex justify-content-center align-items-center ">
              <input
                className="form-control mb-2"
                placeholder="Search Text"
                type="text"
                id="name"
                value={searchText}
                onChange={(e) => SearchText(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
        <div>
          {successMessage && (
            <div className="rowdelete mt-3">{successMessage}</div>
          )}
        </div>
        <table className="table table-hover table-responsive">
          <thead className="bg-black text-white">
            <tr className="">
              {/* <td>#</td> */}
              <td>Name</td>
              <td>Email</td>
              <td>Phone Number</td>
              <td className="text-center">Actions</td>
            </tr>
          </thead>
          <tbody>
            {data.map((data) => (
              <tr key={data.id}>
                {/* <td>{data.index}</td> */}
                <td>{data.name}
                <br />
                {data?.id}
                </td>
                <td>{data.email}</td>
                <td>{data.phone}</td>
                <td className=" d-flex justify-content-around align-items-center text-center">
                  {/* <button className='btn btn-edit' type="button">Edit</button> */}
                  <EditCustomer1 data={data} />
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={() => ViewBooking(data.id)}
                  >
                    View Bookings
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => CustomerDelete(data.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}

            {nodata ? (
              <tr className=" border-0">
                <td className=" border-0">
                  <h1 className="  mt-3 text-center w-auto text-center py-2 mb-3 font-weight-700">
                    No Items Found
                  </h1>
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>

        {/*  */}

        <div className=" mb-5 pb-5 pt-2 mt-4 d-flex justify-content-evenly">
          {!nodata ? (
            <>
              {pageNumber <= 1 ? (
                <button className="btn btn-dark" disabled>
                  &#8249;
                </button>
              ) : (
                <button className="btn btn-dark" onClick={prevButton}>
                  &#8249;
                </button>
              )}
              <p className=" ">
                <span className="font-weight-600">{pageNumber} / </span>{" "}
                <span className="font-weight-600">{bookingPages}</span>{" "}
                <span className=" ms-5">{booksItems} customers</span>
              </p>
              {pageNumber >= bookingPages ? (
                <button className="btn btn-dark" disabled>
                  {" "}
                  &#8250;
                </button>
              ) : (
                <button className="btn btn-dark" onClick={nextButton}>
                  {" "}
                  &#8250;
                </button>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default Customer;
