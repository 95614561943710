import React, { useEffect, useState } from "react";
// import Calendar from "../components/Calendar/Calendar";
import "../components/Filter/Filter.css";
import "../components/Booking/Booking.css";
import moment from "moment";
import "./Booking.css";
import Calendar from "react-calendar";
import "../components/Calendar/ReactCalendar.css";
import AddBooking from "../components/AddBooking/AddBooking";
import UpdateBooking from "../components/UpdateBooking/UpdateBooking";
import loader from "../assets/images/loader.svg";
import auth from "../Auth/jwtMiddleware";

const Bookings = () => {
  // const [fielddata, setFieldData] = useState([]);
  const [servicedata, setServiceData] = useState([]);
  const [service, setService] = useState([]);
  const [date, setDate] = useState(new Date());
  const [duration, setDuration] = useState(60);
  const [pitchdata, setPitchData] = useState([]);
  const [selectedservice, setSelectedService] = useState(0);
  const [isbirthday, setIsBirthday] = useState(false);
  const [selectedbooking, setSelectedBooking] = useState([]);
  const [selectedupdatebooking, setSelectedUpdateBooking] = useState([]);
  const [bookingstatus, setBookingStatus] = useState([]);
  const [paymentstatus, setPaymentStatus] = useState([]);
  const [paymenttype, setPaymentType] = useState([]);
  const [payment, setPayment] = useState([]);
  const [pitchcost, setPitchcost] = useState([]);
  const [birthdaycost, setBirthdayCost] = useState([]);
  const [birthdaycolor, setBirthDayColor] = useState();
  const [pitchcolor, setPitchColor] = useState();
  const [serviceloader, setServiceLoader] = useState(false);
  const [statuseloader, setStatuses] = useState(false);
  const [bookingloader, setBookingTime] = useState(false);
  const [costsloader, setCostsLoader] = useState(false);
  const [birthdayloader, setBirthdayLoader] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [ping, setPing] = useState(0);

  // cons
  // const [duration, setDuration] = useState()
  const api = process.env.REACT_APP_PUBLIC_URL;

  // const eventUrl = process.env.REACT_APP_PUBLIC_URL+'/stream';

  // eslint-disable-next-line
  useEffect(() => {
    getServices();
    // Ping();
  }, []);

  function Ping() {
    setInterval(() => {
      try {
        auth()
          // .get(`https://allstarstports.arenacapital.org/stream`)
          .get(`${api}/stream`)
          .then((res) => {
            // console.log(res);
            if (res?.data) {
              // setPing(ping+1)
            }
          })
          .catch({});
      } catch (error) {
        // console.log();
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.reload(false);
        }
      }
    }, 10000);
  }

  // eslint-disable-next-line
  const getServices = async () => {
    setServiceLoader(true);
    try {
      await auth()
        .get(`${api}/services/get`)
        .then(({ data }) => {
          if (data?.status === 200);
          setServiceData(data?.data);

          setService(data?.data?.[0]);
          // setSelectedService(0)
          getBookingTime(data?.data?.[0]);
          getStatuses();
          setServiceLoader(false);

          data?.data?.forEach((element) => {
            if (element.slug === "birthday-party") {
              setBirthDayColor(element.color);
            } else {
              setPitchColor(element.color);
            }
          });

          // console.log(data?.data?.[0])
          //if(data?.data)
        });
    } catch (error) {
      setServiceLoader(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  async function getStatuses() {
    setStatuses(true);
    try {
      await auth()
        .get(`${api}/bookings/get-statuses`)
        .then(({ data }) => {
          setBookingStatus(data?.booking_statuses);
          setPaymentStatus(data?.payment_statuses);
          setPaymentType(data?.payment_type);
          setPayment(data?.payments);
          getCosts();
          BirthdayCosts();
          setStatuses(false);
          // setPitchData(data.data[0]);
        });
    } catch(error) {
      setStatuses(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  }

  async function getBookingTime(serv, time) {
    let dte = moment(date).format("YYYY-MM-DD");
    // console.log(dte);
    var url = "get-pitch-rental-timeslots";
    let dur = 0;
    if (serv.slug === "pitch-rental") {
      url = "get-pitch-rental-timeslots";
      if (time === undefined) {
        if (duration === 0) {
          dur = "duration=" + 60;
        } else {
          dur = "duration=" + duration;
        }
      } else {
        dur = "duration=" + time;
      }
      setIsBirthday(false);
    } else {
      url = "get-all-birthday-timeslots";
      dur = "duration=120";
      setIsBirthday(true);
    }

    try {
      setBookingTime(true);
      await auth()
        .post(`${api}/timeslots/${url}?${dur}&date=${dte}`)
        .then(({ data }) => {
          // console.log(data);
          if (data.status == 200) {
            setPitchData(data.data[0]);
          } else {
            // console.log(data);
          }

          setBookingTime(false);
        });
    } catch (error) {
      setBookingTime(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  }

  async function getCosts() {
    setCostsLoader(true);
    try {
      await auth()
        .post(`${api}/bookings/get-pitch-rental-costs`)
        .then(({ data }) => {
          setCostsLoader(false);
          setPitchcost(data?.data);
        })
        .catch((e) => {
          setCostsLoader(false);
          // this.bookingsloader = false;
        });
    } catch (error) {
      setCostsLoader(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      // this.bookingsloader = false;
    }
  }

  async function BirthdayCosts() {
    setBirthdayLoader(true);
    try {
      await auth()
        .post(`${api}/bookings/get-bday-costs`)
        .then(({ data }) => {
          setBirthdayLoader(false);
          //  console.log(data?.data);
          setBirthdayCost(data?.data);
        })
        .catch((e) => {
          setBirthdayLoader(false);
          // this.bookingsloader = false;
        });
    } catch (error) {
      setBirthdayLoader(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      // this.bookingsloader = false;
    }
  }

  function onChangeDay(date) {
    setDate(date);
  }

  function getBookingService(data, id) {
    // console.log(id)
    setSelectedService(id);
    setService(data);
    getBookingTime(data);
  }

  function getDuration(time) {
    setDuration(time);
  }

  const addBookingModal = (booking, alldata, indx, totalLength) => {
    //
    // console.log(bookings?.data.length)
    // console.log(alldata);
    // console.log(booking.start)
    // console.log()
    //
    var startmax;
    if((Number(indx)+1) ===totalLength){
      startmax=booking.start
   
    }
    else{
      startmax= moment(booking.start).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    }
    let newdata = {};
    if (service.slug === "pitch-rental") {
      newdata = {
        duration: duration,
        service: service.slug,
        fieldslug: alldata.slug,
        start: booking.start,
        startmax:startmax,
        end: booking.end,
        servicename: service.name,
        bookingstatus: bookingstatus,
        paymentstatus: paymentstatus,
        paymenttype: paymenttype,
        payment: payment,
        pitchcost: pitchcost[alldata.slug][duration],
        maxforward :booking?.max_forward
      };
    } else {
      let serv = [];
      servicedata.forEach((element) => {
        if (element.slug === "birthday-party") {
          serv = element.extras;
        }
      });
      // console.log(serv);
      newdata = {
        duration: 120,
        service: service.slug,
        fieldslug: alldata.slug,
        start: booking.start,
        startmax: startmax,
        end: booking.end,
        servicename: service.name,
        bookingstatus: bookingstatus,
        paymentstatus: paymentstatus,
        paymenttype: paymenttype,
        payment: payment,
        pitchcost: pitchcost[alldata.slug]["120"],
        birthdaycost: serv,
        maxforward :booking?.max_forward
      };
    }

    //  console.log(newdata);

    setSelectedBooking(newdata);
    setShowModal(!showModal);
  };

  useEffect(() => {
    getBookingTime(service);
  }, [selectedbooking, duration, date, ping]);

  const handleValueChange = (value) => {
    getBookingTime(service);
    setShowModal(value);
  };

  const handleValueUpdate = (value) => {
    // window.
    getBookingTime(service);
    setShowModalUpdate(value);
  };

  const updateBookingModal = (booking, alldata, indx, totalLength) => {
    var startmax;
    if((Number(indx)+1) ===totalLength){
      startmax=booking?.booking[0]?.start
        // console.log('end')
    }
    else{
      // console.log('12121')
      startmax= moment(booking?.booking[0]?.start).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    }

    let hasrecurring = false;
    let recurringlength = 0;
    let recurringdata = [];
    let recurringtype = "";
    let recurringdays = [];
    if (booking.booking[0]?.recurring?.data?.length > 0) {
      // console.log(booking?.booking[0]?.recurring)
      // console.log(booking?.booking[0]?.recurring?.days)
      // console.log(JSON.parse(booking?.booking[0]?.recurring?.days))

      hasrecurring = true;
      recurringlength = booking.booking[0]?.recurring?.length;
      recurringdata = booking.booking[0]?.recurring?.data;
      recurringtype = booking.booking[0]?.recurring?.type;
      recurringdays = JSON.parse(booking?.booking[0]?.recurring?.days);
    }

    let newdata = {};

    if (service.slug === "pitch-rental") {
      // console.log("pitch");

      let serv = [];
      servicedata.forEach((element) => {
        if (element.slug === "birthday-party") {
          serv = element.extras;
        }
      });
      newdata = {
        id: booking.booking[0].id,
        duration: booking?.booking[0]?.duration,
        service: booking?.booking[0]?.service.slug,
        fieldslug: alldata?.slug,
        start: booking?.booking[0]?.start,
        end: booking?.booking[0]?.end,
        servicename: booking?.booking[0]?.service?.name,
        bookingstatus: bookingstatus,
        booking_status_slug: booking?.booking[0]?.booking_status_slug,
        paymentstatus: paymentstatus,
        payment_status_slug: booking?.booking[0]?.payment_status_slug,
        paymenttype: paymenttype,
        payment_type_slug: booking?.booking[0]?.payment_type_slug,
        payment: payment,
        pitchcost: pitchcost[alldata.slug][duration],
        payment_slug: booking?.booking[0]?.payment_slug,
        customer: booking?.booking[0]?.customer,
        title: booking?.booking[0]?.title,
        total: booking?.booking[0]?.total,
        notes: booking?.booking[0]?.notes,
        birthdaycost: serv,
        paid: booking?.booking[0]?.paid,
        payment_url: booking?.booking[0]?.payment_url,
        birthday_extra_service: booking?.booking[0]?.service_extra_slug,
        qty: booking?.booking[0]?.qty,
        hasrecurring: hasrecurring,
        recurringlength: recurringlength,
        recurringdata: recurringdata,
        recurringtype: recurringtype,
        recurringdays: recurringdays,
        overallpaid: booking?.booking?.overall_paid,
        overalltotal: booking?.booking?.overall_total,
        maxforward :booking?.max_forward
      };
    } else {
      let serv = [];
      servicedata.forEach((element) => {
        if (element.slug === "birthday-party") {
          serv = element.extras;
        }
      });

      newdata = {
        id: booking.booking[0].id,
        duration: booking?.booking[0]?.duration,
        service: booking?.booking[0]?.service.slug,
        fieldslug: alldata?.slug,
        start: booking?.booking[0]?.start,
        end: booking?.booking[0]?.end,
        servicename: booking?.booking[0]?.service?.name,
        bookingstatus: bookingstatus,
        booking_status_slug: booking?.booking[0]?.booking_status_slug,
        paymentstatus: paymentstatus,
        payment_status_slug: booking?.booking[0]?.payment_status_slug,
        paymenttype: paymenttype,
        payment_type_slug: booking?.booking[0]?.payment_type_slug,
        payment: payment,
        payment_slug: booking?.booking[0]?.payment_slug,
        customer: booking?.booking[0]?.customer,
        title: booking?.booking[0]?.title,
        birthdaycost: serv,
        pitchcost: pitchcost[alldata.slug]["120"],
        total: booking?.booking[0]?.total,
        notes: booking?.booking[0]?.notes,
        paid: booking?.booking[0]?.paid,
        qty: booking?.booking[0]?.qty,
        payment_url: booking?.booking[0]?.payment_url,
        birthday_extra_service: booking?.booking[0]?.service_extra_slug,
        hasrecurring: hasrecurring,
        recurringlength: recurringlength,
        recurringdata: recurringdata,
        recurringtype: recurringtype,
        recurringdays: recurringdays,
        overallpaid: booking?.booking?.overall_paid,
        overalltotal: booking?.booking?.overall_total,
        maxforward :booking?.max_forward
      };
    }

    //
    // console.log(newdata)
    setSelectedUpdateBooking(newdata);
    setShowModalUpdate(!showModalUpdate);
  };

  return (
    <>
      {serviceloader ||
      statuseloader ||
      bookingloader ||
      costsloader ||
      birthdayloader ? (
        <>
          <div id="loader">
            <img src={loader} width="100" alt="Loader" />
          </div>
        </>
      ) : (
        <>
          {/*         
        <div id="loader">
            <img src={loader} width="100" alt="Loader" />
          </div> */}
        </>
      )}
      <div className="container mb-4">
        <div className="row mb-4">
          <h1 className="text-center py-2 mb-3 font-weight-700">
            Booking <span className="calendar-text">Calendar</span>
          </h1>
        </div>
        <div className="row gap-3">
          <div className="col-md-3 bg-f7 p-3 pr-4 ">
            <Calendar
              onChange={onChangeDay}
              showMonthDropdown
              showFixedNumberOfWeeks
              value={date}
              minDate={new Date()}
            />

            {/* filter */}

            <div className="clearfix-1"> </div>
            <h3 className="font-weight-600 text-black pt-4 pb-3 text-uppercase">
              Filters
            </h3>
            {/* Services /> */}
            <h4 className="font-weight-600 text-black  pb-3 ">Services</h4>

            <div className="d-block justify-content-around align-items-center">
              {Object.entries(servicedata).map(([fieldId, bookings]) => (
                // console.log(fieldId),
                // console.log(selectedservice),
                <button
                  className={
                    selectedservice == fieldId
                      ? "selected-service btn btn-default no-wrap m-r-15 mb-3 text-uppercase"
                      : " btn btn-default no-wrap m-r-15 mb-3 text-uppercase"
                  }
                  type="button"
                  key={fieldId + "-btn-" + bookings.name}
                  onClick={() => getBookingService(bookings, fieldId)}
                >
                  {bookings.name}
                </button>
              ))}
            </div>

            {/* Time  */}
            {/* <h4 className="font-weight-600 text-black pt-4 pb-3 ">Time</h4> */}

            <div className="d-block justify-content-around align-items-center">
              <>
                {isbirthday === false ? (
                  <>
                  {/* 
                    <button
                      className={
                        duration === 60
                          ? "btn btn-default no-wrap m-r-15 mb-3 text-uppercase selected-duration"
                          : "btn btn-default no-wrap m-r-15 mb-3 text-uppercase"
                      }
                      type="button"
                      key={`duration-60`}
                      onClick={() => getDuration(60)}
                    >
                      60 Minutes
                    </button>

                    <button
                      className={
                        duration === 90
                          ? "btn btn-default no-wrap m-r-15 mb-3 text-uppercase selected-duration"
                          : "btn btn-default no-wrap m-r-15 mb-3 text-uppercase"
                      }
                      type="button"
                      key={`duration-90`}
                      onClick={() => getDuration(90)}
                    >
                      90 Minutes
                    </button>

                    <button
                      className={
                        duration === 120
                          ? "btn btn-default no-wrap m-r-15 mb-3 text-uppercase selected-duration"
                          : "btn btn-default no-wrap m-r-15 mb-3 text-uppercase"
                      }
                      type="button"
                      key={`duration-120`}
                      onClick={() => getDuration(120)}
                    >
                      120 Minutes
                    </button> */}
                  </>
                ) : (
                  <>
                  
                  {/* <button
                    className="btn btn-default no-wrap m-r-15 mb-3 text-uppercase selected-duration"
                    type="button"
                    key={`duration-120`}
                  >
                    120 Minutes
                  </button> */}
                  
                  </>
                )}
              </>
            </div>

            <div className="clearfix-1"> </div>
          </div>
          {/*  */}
          <div className="col-md-8 bg-f7 p-3">
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              {Object.entries(pitchdata).map(([fieldIdMain, bookings]) => (
                // console.log(bookings),
                <>
                  <div
                    className="col event-col"
                    key={fieldIdMain + "main-col-"}
                  >
                    <h4 className="font-weight-600 text-black ">
                      {fieldIdMain}
                    </h4>
                    <p className="font-weight-500 text-black  pb-1">
                      {bookings.size}
                    </p>

                    {bookings?.data.length > 0 ? (
                      <>
                        {Object.entries(bookings?.data).map(
                          ([fieldId, bookingdata]) => (
                            // console.log(bookings.available)
                            <>
                              {/* <div>{fieldId}</div> */}
                              {bookingdata.available == true ? (
                                <div
                                  key={fieldId + `---` + bookingdata?.start}
                                  className="event"
                                  onClick={() =>
                                    addBookingModal(bookingdata, bookings,fieldId ,bookings?.data.length)
                                  }
                                >
                                  {moment(bookingdata.start).format("HH:mm")} - {moment(bookingdata.end).format("HH:mm")} 
                                </div>
                              ) : (
                                <>
                                  {bookingdata.booking[0]?.service?.slug ===
                                  "birthday-party" ? (
                                    <>
                                      <div
                                        key={
                                          fieldId + `-----` + bookingdata?.start
                                        }
                                        className="event-birthday"
                                        style={{
                                          backgroundColor: birthdaycolor,
                                        }}
                                        onClick={() =>
                                          updateBookingModal(
                                            bookingdata,
                                            bookings,
                                            fieldId,
                                            bookings?.data.length
                                          )
                                        }
                                      >
                                        <span className=" py-2">
                                        Booked
                                        {/* <br /> */}
                                        <span className=" bookpx">  ({moment(bookingdata.booking[0]?.start).format("HH:mm")} - {moment(bookingdata.booking[0]?.end).format("HH:mm")})</span>
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        key={
                                          fieldId + `-----` + bookingdata?.start
                                        }
                                        className="event-pitch"
                                        style={{ backgroundColor: pitchcolor }}
                                        onClick={() =>
                                          updateBookingModal(
                                            bookingdata,
                                            bookings,
                                            fieldId,
                                            bookings?.data.length
                                          )
                                        }
                                      >
                                        <span className=" py-2">
                                        Booked 
                                        {/* <br /> */}
                                        <span className=" bookpx">  ({moment(bookingdata.booking[0]?.start).format("HH:mm")} - {moment(bookingdata.booking[0]?.end).format("HH:mm")})</span>
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <h4 className="small-heading mb-20 alert alert-warning mt-5">
                          No available timeslots for this day
                        </h4>
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <AddBooking Data={selectedbooking} onValueChange={handleValueChange} />
      )}

      {showModalUpdate && (
        <UpdateBooking
          Data={selectedupdatebooking}
          onValueChange={handleValueUpdate}
        />
      )}

      <div></div>
    </>
  );
};
export default Bookings;
