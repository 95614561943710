import React, { useState, useEffect } from "react";
import auth from "../../Auth/jwtMiddleware";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./UpdateBooking.css";
import loader from "../../assets/images/loader.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import copyImage from "../../assets/copy.svg";
import * as clipboard from "clipboard-polyfill";
import {setHours, setMinutes} from 'date-fns'
//

const api = process.env.REACT_APP_PUBLIC_URL;

const UpdateBooking = (data) => {
  // console.log(data?.Data);
  //
  const [name, setName] = useState(data?.Data?.customer?.name);
  const [email, setEmail] = useState(data?.Data?.customer?.email);
  const [phone, setPhone] = useState(data?.Data?.customer?.phone);
  const [title, setTitle] = useState(data?.Data?.title);

  // const [start, setStart] = useState(new Date((data?.Data?.start).replace(/-/g, "/")));
  // const [start1, setStart1] = useState(new Date());
  // const [start, setStart] = useState(moment(data?.Data?.start).format("YYYY-MM-DD HH:mm:ss"));
  const [start, setStart] = useState(moment(data?.Data?.start).toDate());
  // console.log(data?.Data?.start)
  // console.log(start)
  // console.log(start1)
  // const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(
    moment(data?.Data?.end).format("YYYY-MM-DD HH:mm:ss")
  );

   
  const [minstart] = useState(moment(data?.Data?.start).toDate());

  const [note, setNotes] = useState(data?.Data?.notes);
  const [field, setField] = useState(data?.Data?.fieldslug);
  const [service, setService] = useState(data?.Data?.servicename);
  const [serviceslug] = useState(data?.Data?.service);
  const [payment, setPayment] = useState(data?.Data?.payment_slug);
  const [quantity, setQuantity] = useState(data?.Data.qty);
  const [duration, setDuration] = useState(data?.Data?.duration);
  const durationint = data?.Data?.duration;
  const [displayduration, setDisplayDuration] = useState(
    data?.Data?.duration + " Minutes"
  );
  const [maxforward,setMaxForward] = useState([data?.Data?.maxforward]);
  const [selectDuration, setSelectDuration] = useState([]);
  const [paid, setPaid] = useState(data?.Data?.paid);
  const [bookingstatus] = useState(data?.Data?.bookingstatus);
  const [paymentstatus] = useState(data?.Data?.paymentstatus);
  const [paymenttype] = useState(data?.Data?.paymenttype);
  const [paymentt] = useState(data?.Data?.payment);
  const [birthdaycost] = useState(data?.Data?.birthdaycost);
  const [birthdayprice, setBirthdayPrice] = useState("");
  const [hasrecurring, setHasRecurring] = useState(data.Data?.hasrecurring);
  const [overalltotal, setHasOverallTotal] = useState(data.Data?.overalltotal);
  const [overallpaid, setHasOverallPayment] = useState(data.Data?.overallpaid);
  const [birthdaytotalcost, setBirthdayTotalCost] = useState(
    data?.Data?.overalltotal
  );
  const [recurring, setRecurring] = useState(false);
  const [editrecurring, setEditrecurring] = useState(false);

  const [length, setLength] = useState();

  const [period, setPeriod] = useState(data?.Data?.recurringtype);
  const [recurringData, setRecurringData] = useState(data?.Data?.recurringdata);
  const [recurringDataEdit, setRecuccingDataEdit] = useState([]);
  const [recurhasConflict, setRecuHasConflict] = useState(false);

  const [payrecurral, setPayRecurral] = useState([]);

  // console.log(start);

  // datepickers
  const [startDate, setStartDate] = useState(new Date());

  // console.log(startDate)

  const [payment_type, setPayment_Type] = useState(
    data?.Data?.payment_type_slug
  );
  const [payment_status, setPayment_Status] = useState(
    data?.Data?.payment_status_slug
  );
  const [booking_status, setBooking_Status] = useState(
    data?.Data?.booking_status_slug
  );
  const [totalcost, setTotalCost] = useState(data?.Data?.total);

  const [bookingId] = useState(data?.Data?.id);

  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [failuretext, setFailureText] = useState("");
  const [paymenturl, setPaymentUrl] = useState(data?.Data?.payment_url);
  const [birthdayextraservice, setBirthDayExtraService] = useState(
    data?.Data?.birthday_extra_service
  );
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [paymentbutton, setPaymentButton] = useState(false);

  const min = 10;
  const max = 99999;



  // console.log(birthdaycost);

  const [inputValue, setInputValue] = useState("");

  const [running, setRunning] = useState(false);
  const initialDates = [
    { id: 0, name: "M", shouldBeChecked: false },
    { id: 1, name: "T", shouldBeChecked: false },
    { id: 2, name: "W", shouldBeChecked: false },
    { id: 3, name: "T", shouldBeChecked: false },
    { id: 4, name: "F", shouldBeChecked: false },
    { id: 5, name: "S", shouldBeChecked: false },
    { id: 6, name: "S", shouldBeChecked: false },
  ];
  const [customdates, setCustomDates] = useState(initialDates);
  const [checkedDates, setCheckedDates] = useState([]);
  const [hascustomweek, setHasCustomWeek] = useState(false);

  const [recurrPayment, setRecurrPayment] = useState();

  useEffect(() => {
    //
    if (data?.Data?.recurringdays) {
      //

      let int = [];
      data?.Data?.recurringdays.forEach((e) => {
        int.push(parseInt(e));
      });

      const newState = customdates.map((item) => {
        const filt = data?.Data?.recurringdays.includes(item.id.toString());

        if (filt === true) {
          return { ...item, shouldBeChecked: !item.shouldBeChecked };
        } else {
          return item;
        }
      });

      setCustomDates(newState);
    }
  }, [data?.Data?.recurringdays]);

  useEffect(() => {
    // data?.Data?.recurringdays
    // console.log(checkedDates)
  }, [checkedDates, payrecurral]);

  const handleCheckboxChange = (itemId) => {
    if (running === true) {
      return;
    }
    setRunning(true);

    if (checkedDates.length > 0) {
      let items = checkedDates;

      let containsItem = checkedDates.includes(itemId);
      if (containsItem) {
        items.splice(itemId);
      } else {
        items.push(itemId);
      }
      setCheckedDates(items);
    } else {
      setCheckedDates([itemId]);
    }

    const newState = customdates.map((item) => {
      if (item.id === itemId) {
        return { ...item, shouldBeChecked: !item.shouldBeChecked };
      }
      return item;
    });

    setCustomDates(newState);

    setRunning(false);
  };

  const handleChange = (event) => {
    data?.onValueChange(false);
    event.preventDefault();
  };

  const handleQuantity = (event) => {
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setQuantity(value);
    // console.log(birthdayprice)
    if (birthdayprice) {
      setBirthdayTotalCost(birthdayprice * quantity);
      handleRecurringQuantity(0, birthdaycost[0]?.rate * value);
      // setTotalCost(birthdayprice * quantity);
    } else {
      setBirthdayTotalCost(birthdayprice * quantity);
      handleRecurringQuantity(0, birthdaycost[0]?.rate * value);
      // setTotalCost(birthdaycost[0]?.rate * quantity);
    }
  };

  const handleRecurringQuantity = (qty, value) => {
    // console.log('lkdlskdsldk')
    if (serviceslug === "pitch-rental") {
      // setTotalCost(Number(pitchcost) * ((Number(qty) + 1)))
      // console.log('coooooooost')
    } else {
      setTotalCost(Number(value) * (Number(qty) + 1));
    }
  };

  const handleCatering = (data) => {
    birthdaycost.forEach((element) => {
      if (element.slug === data) {
        setBirthdayPrice(element.rate);
        setTotalCost(element?.rate * quantity);
        setBirthDayExtraService(data);
      }
    });
  };

  useEffect(() => {
    birthdaycost.forEach((element) => {
      if (element.slug === birthdayextraservice) {
        setBirthdayPrice(element.rate);
        setTotalCost(element?.rate * quantity);
        // setBirthDayExtraService(data)
      }
    });
  }, []);

  useEffect(()=>{
    // 
    // console.log('thidsd')

    var maxfrd =[
      60,90,120
    ]
    // console.log(data?.Data?.maxforward);
    if(data?.Data?.maxforward){
      // console.log(maxforward)
      setMaxForward(data?.Data?.maxforward)
      setSelectDuration(data?.Data?.maxforward[0]);
    }
    else{
      // console.log(data?.Data?.maxforward)
      setSelectDuration(maxfrd);
      setMaxForward(maxfrd)
    }

    // if(serviceslug === "birthday-party"){

    // }
    // else{

    // }

  },[])

  useEffect(() => {
    if (data.Data?.hasrecurring) {
      setRecurring(true);
    } else {
      setRecurring(false);
    }
    // data?.Data?.recurringdata
    if (data?.Data?.recurringlength > 0) {
      setLength(data?.Data?.recurringlength);
    } else {
      setLength("");
    }
    if (data?.Data?.recurringtype === "weekly-custom") {
      setHasCustomWeek(true);
    }
  }, [data.Data?.hasrecurring]);

  // console.log(recurring)

  const saveBooking = async (e) => {
    e.preventDefault();
    let datasend = {};
    // setLoading(true);

    let update_recurring = null;

    // console.log(editrecurring, period, length)
    if (
      editrecurring &&
      period &&
      length &&
      hascustomweek &&
      checkedDates.length > 0
    ) {
      update_recurring = {
        type: period,
        length: length,
        days: checkedDates.sort().toString(),
      };
    } else if (editrecurring && period && length) {
      update_recurring = {
        type: period,
        length: length,
      };
    }
    let date = moment(start).format("YYYY-MM-DD HH:mm:ss");

    if (serviceslug === "pitch-rental") {
      if (payment === "on-site") {
        // console.log("onsite");
        datasend = {
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: date,
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          duration: data?.Data?.duration,
          paid: Number(paid),
          id: bookingId,
          update_recurring: update_recurring,
          booking_status_slug: booking_status,
        };
      } else {
        // console.log('onsite else');

        datasend = {
          id: bookingId,
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: date,
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          duration: data?.Data?.duration,
          paid: Number(paid),
          update_recurring: update_recurring,
          booking_status_slug: booking_status,
        };
      }
    } else {
      // console.log(" else");

      if (payment === "on-site") {
        // console.log("onsite b-th");
        datasend = {
          id: bookingId,
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: date,
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          duration: data?.Data?.duration,
          birthday: birthdayextraservice,
          quantity: quantity,
          paid: Number(paid),
          update_recurring: update_recurring,
          booking_status_slug: booking_status,
        };
      } else {
        // console.log("offsite b-th");
        datasend = {
          id: bookingId,
          customer: {
            name: name,
            phone: phone,
            email: email,
          },
          title: title,
          start: date,
          end: end,
          notes: note,
          field: field,
          service_slug: serviceslug,
          payment_type_slug: payment_type,
          payment_slug: payment,
          quantity: quantity,
          duration: data?.Data?.duration,
          birthday: birthdayextraservice,
          paid: Number(paid),
          update_recurring: update_recurring,
          booking_status_slug: booking_status,
        };
      }
    }

    // console.log(datasend);

    try {
      setFailure(false);
      setSuccess(false);

      await auth()
        .put(`${api}/bookings/update`, datasend)
        .then(({ data }) => {
          // console.log(data);
          setLoading(false);
          if (data.status === 200) {
            setSuccess(true);

            setMessage(data.message);
            // console.log(data.data)
            // setPaymentUrl(data.data.payment_url);
          } else if (data.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          } else {
            setFailureText(data.message);
            setFailure(true);
          }
        });
    } catch (error) {
      setLoading(false);
      setFailureText("We have encoutered an Error, Please try again later");
      setFailure(true);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFailure(false);
    setSuccess(false);
    try {
      await auth()
        .delete(`${api}/bookings/delete?id=${bookingId}`)
        .then((res) => {
          if (res.data.status === 200) {
            data?.onValueChange(false);
          } else if (res.data.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          } else {
            setFailureText(res.data.message);
            setFailure(true);
          }
          setLoading(false);
        });
    } catch (error) {
      // data?.onValueChange(false);
      setFailureText("We have encountered an error please try again later");
      setFailure(true);
      // console.log(bookingId);
      setLoading(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  const handleDeleteAllWithRecurring = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFailure(false);
    setSuccess(false);
    try {
      await auth()
        .delete(`${api}/bookings/delete-with-recurring?id=${bookingId}`)
        .then((res) => {
          if (res.data.status === 200) {
            data?.onValueChange(false);
          } else if (res.data.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          } else {
            setFailureText(data.message);
            setFailure(true);
          }
          setLoading(false);
        });
    } catch (error) {
      // data?.onValueChange(false);
      setFailureText(error?.response?.message);
      setFailure(true);
      // console.log(bookingId);
      setLoading(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  const handleRecurring = () => {
    setEditrecurring(!editrecurring);
    // console.log(editrecurring)
  };

  const handleDuration = (data) => {
    setHasCustomWeek(false);
    setPeriod(data);
    let len = length;
    if (data === "weekly-custom") {
      setHasCustomWeek(true);
      if (checkedDates.length > 0) {
        setLength(len);
        getRecurring(data, len);
        return;
      }

      return;
    } else {
      if (data === "monthly" && length > 2) {
        len = 2;
      }

      setLength(len);
      getRecurring(data, len);
    }
    // console.log(data.slug)
    //  setSelectedProfileType(data.slug);
    setPeriod(data);
  };

  const handleDate = (data) => {

    // console.log('maxforward')
    // console.log(maxforward.length)
    if (moment(data).isSame(minstart)) {

        if(maxforward?.length===3){
          setSelectDuration(maxforward);
        }
        else{
          setSelectDuration(maxforward[0]);
        }
   

    } else {
      if(maxforward?.length===3){
        setSelectDuration(maxforward);
      }
        else{
          setSelectDuration(maxforward[1]);
        }
     
    }
    setStart(data);
    setEnd(moment(data).add(duration, "minutes").format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleDurationChange =(data) =>{
    // console.log(data)
    setDuration(data)
    setEnd(moment(start).add(data, "minutes").format("YYYY-MM-DD HH:mm:ss"));
  }

  const copyToClipboard = async (text) => {
    try {
      await clipboard.writeText(text);
      // console.log("Text copied to clipboard:", text);
      window.alert(`Payment Link copied`);
    } catch (error) {
      // console.error("Error copying to clipboard:", error);
      window.alert(`Error copying to clipboard: ${text}`);
    }
  };

  const bookingDeleteRecurring = async (id, key) => {
    let newdata = recurringData;

    setLoading(true);

    try {
      await auth()
        .delete(`${api}/bookings/delete-single-recurring?recurring_id=${id}`)
        .then((data) => {
          setLoading(false);

          // console.log(data?.data)

          // console.log(newdata.length);
          if (data?.data?.status === 200 || data?.data?.status === 422) {
            let total =
              Number(overalltotal) -
              Number(
                recurringData[key]?.booking?.total -
                  Number(recurringData[key]?.booking?.paid)
              );

            newdata.splice(key, 1);
            setHasOverallTotal(total);
            setRecurringData(newdata);
          }

          if (data.code === 401) {
            localStorage.clear();
            window.location.reload(false);
          }
        })
        .catch((error) => {
          if (data.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          }
          // setShowMessage('We Encountered an Error Please Try again Later');
          // console.log(error);
        });
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      setLoading(false);
    }
  };

  const bookingDeleteAllRecurring = async (id) => {
    // console.log(id)
    setLoading(true);
    try {
      await auth()
        .delete(
          `${api}/bookings/delete-all-recurring-only?booking_id=${bookingId}`
        )
        .then((data) => {
          setLoading(false);
          // console.log(data);
          setRecurringData([]);
          setRecurring(false);
          if (data.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          }
        });
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  //
  const getRecurring = async (perioddata, lengthdata) => {
    // console.log(hascustomweek);
    if (
      (perioddata && lengthdata && period && length && !hascustomweek) ||
      (perioddata &&
        lengthdata &&
        period &&
        length &&
        hascustomweek &&
        checkedDates.length > 0)
    ) {
      // console.log("fa;se");
      // setIsLoadingRecur(true);
      setRecuHasConflict(false);

      let urlobject = "";

      // console.log(recurringData)

      let date = moment(start).format("YYYY-MM-DD HH:mm");
      let urlstring = "";

      // if (hascustomweek)
      if (recurringData.length > 0) {
        // recurringData[0]?.group
        if (hascustomweek === true) {
          urlstring =
            "type=" +
            perioddata +
            "&length=" +
            lengthdata +
            "&field=" +
            field +
            "&start=" +
            date +
            "&end=" +
            end +
            "&group=" +
            recurringData[0]?.group +
            "&days=" +
            checkedDates.sort();
        } else {
          urlstring =
            "type=" +
            perioddata +
            "&length=" +
            lengthdata +
            "&field=" +
            field +
            "&start=" +
            date +
            "&end=" +
            end +
            "&group=" +
            recurringData[0]?.group;
        }
      } else {
        if (hascustomweek === true) {
          urlstring =
            "type=" +
            perioddata +
            "&length=" +
            lengthdata +
            "&field=" +
            field +
            "&start=" +
            date +
            "&end=" +
            end +
            "&days=" +
            checkedDates.sort();
        } else {
          urlstring =
            "type=" +
            perioddata +
            "&length=" +
            lengthdata +
            "&field=" +
            field +
            "&start=" +
            date +
            "&end=" +
            end;
        }
      }

      // console.log(moment(start).format("YYYY-MM-DD HH:mm"))

      // if()
      try {
        setLoading(true);

        await auth()
          .get(`${api}/bookings/recurring?${urlstring}`)
          .then((data) => {
            if (data.status === 200) {
              setLoading(false);
              // console.log(data.status)
              if (Array.isArray(data?.data)) {
                setRecuccingDataEdit(data?.data);
              } else if (typeof data?.data === "object") {
                if (Array.isArray(data?.data)) {
                  setRecuccingDataEdit(data?.data);
                }
              }

              // setRecuccingDataEdit(data?.data);
              data?.data.forEach((element) => {
                if (element.booking !== false) {
                  setRecuHasConflict(true);
                }
                return;
              });
            }
            if (data.status === 401) {
              localStorage.clear();
              window.location.reload(false);
            } else {
            }
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.reload(false);
        }
      }
    }
  };

  const handleRefreshPaymentUrl = async () => {
    // console.log(paymenturl);
    setLoading(true);
    setSuccess(false);
    try {
      await auth()
        .post(`${api}/bookings/refresh-payment-link?booking_id=${bookingId}`)
        .then((data) => {
          setLoading(false);
          // console.log(data?.data?.data);
          if (data?.data?.status === 200) {
            setPaymentUrl(data?.data?.data?.payment_url);
            // data?.data?.data
          }
        });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  const handleLength = (event) => {
    let len = event.target.value;
    if (period === "monthly" && Number(event.target.value) > 2) {
      len = 2;
    }
    setLength(len);
    // console.log(e.target.value)
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getRecurring(period, length);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [length]);

  const bookingPaid = async (data, id) => {
    // console.log(id);
    // console.log(data);
    // console.log(recurringData[id].booking);

    // overalltotal  -> hasrecurring
    // setHasOverallTotal()
    // overalltotal
    // console.log(overallpaid)

    setLoading(true);
    try {
      await auth()
        .put(`${api}/bookings/set-as-paid?booking_id=${data}`)
        .then((data) => {
          setLoading(false);
          // console.log(data?.data?.data);

          // console.log(data);
          if (data.status === 200) {
            let datr = [];
            recurringData.map((recdata, bookid) => {
              let rcd = { recdata };
              if (id === bookid) {
                rcd = { ...recdata, payment_status: "" };
                // console.log(rcd)
                //
                datr.push(rcd);
              } else {
                datr.push(recdata);
              }
              return rcd;
            });

            let paid =
              Number(overallpaid) +
              (Number(recurringData[id]?.booking?.total) -
                Number(recurringData[id]?.booking?.paid));

            setHasOverallPayment(paid);

            setRecurringData(datr);
          }
        });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  const [boxcheck, setBoxCheck] = useState(false);

  function setPaymentRecurral(id, data) {
    // console.log(boxcheck);

    if (boxcheck === true) {
      setBoxCheck(false);
    } else {
      setBoxCheck(true);
      if (payrecurral.length > 0) {
        let items = payrecurral;
        const index = payrecurral.indexOf(id);
        if (payrecurral.filter((value) => value === id).length > 0) {
          items.splice(index, 1);
        } else {
          items.push(id);
        }
        setPayRecurral(items);
        checkRecurral(items);
      } else {
        setPayRecurral([id]);
        checkRecurral([id]);
      }
      setBoxCheck(false);
    }

    setTimeout(() => {}, 300);
  }

  function checkRecurral(data) {
    // console.log(data);
    // console.log(payrecurral);
    // console.log(payrecurral?.length);
    if (data?.length > 0) {
      // console.log(payrecurral)
      // console.log('trur')
      setPaymentButton(true);
    } else {
      // console.log('false')

      setPaymentButton(false);
    }
  }

  const generatePaymentLink = async () => {
    if (payrecurral.length > 0) {
      setLoading(true);
      let bookdata = {
        bookings: payrecurral,
      };
      try {
        await auth()
          .post(`${api}/bookings/create-recurring-payment`, bookdata)
          .then((data) => {
            setLoading(false);
            // console.log(data?.data);
            if (data?.data?.status === 200) {
              // console.log(data?.data?.data?.payment_url)
              setRecurrPayment(data?.data?.data?.payment_url);
              // console.log(data?.data?.data)
            }
          });
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.reload(false);
        }
      }
    }
  };



  return (
    <>
      {loading ? (
        <>
          <div id="loader">
            <img src={loader} width="100" alt="Loader" />
          </div>
        </>
      ) : (
        <></>
      )}

      {/*  */}
      <div className="row mt-3 mb-3  d-flex justify-content-center align-items-center ">
        <div className="col-md-12">
          <div
            className="modal "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <form onSubmit={saveBooking}>
                  <div className="modal-header">
                    <h5 className="modal-title">Update Booking</h5>
                    <button
                      type="button"
                      className="close"
                      value={inputValue}
                      onClick={handleChange}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-header">
                    <div className="modal-body gap-3">
                      <p className="text-muted mb-2 fs-18">Customer Details</p>
                      <input
                        className="form-control mb-2"
                        placeholder="Enter Full Name"
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      ></input>
                      <input
                        className="form-control mb-2"
                        placeholder="Enter Email ID"
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      ></input>
                      <input
                        className="form-control mb-2"
                        placeholder="Enter Phone Number"
                        type="text"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        readOnly
                      ></input>
                    </div>
                  </div>

                  <div className="modal-body gap-3">
                    <p className="text-muted mb-2 fs-18">Booking Details</p>
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          className="form-control mb-2"
                          placeholder="Title"
                          type="text"
                          id="phone"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        ></input>
                      </div>
                      <div className="col-md-6">
                        <label className=" my-1">Start Time</label>
                        <DatePicker
                          className="form-control mb-2 col-md-6"
                          showIcon
                          id="start"
                          selected={start}
                          onChange={(date) => handleDate(date)}
                          showTimeSelect
                          dateFormat="yyyy-MM-d HH:mm"
                          // timeIntervals={data?.Data?.duration}
                          minDate={new Date()}
                          minTime={setHours(setMinutes(new Date(),0),9)}
                          maxTime={setHours(setMinutes(new Date(),0),19)}
                          required
                        />
                        {/* <Datetime 
                        // dateF     
                        
                        clearIcon={null}
                        format="y-MM-dd HH:mm"
                        // onChange={handleStartDateChanges}
                        disableClock
                        disableCalendar
                        value={start}
                        locale="en_GB"
                        timeFormat="HH:mm"
                        is24Hourormat="yyyy-MM-d HH:mm"
                        
                        /> */}
                      </div>
                      <div className="col-md-6">
                        <label className=" my-1">End Time</label>
                        {/* <DatePicker 
                        className="form-control mb-2 col-md-6"
                        showIcon
                        id="end"
                        selected={end} 
                        onChange={(date) => setEnd(date)} 
                        showTimeSelect
                        dateFormat="yyyy-MM-d HH:mm"
                        required
                        readOnly
                        // timeIntervals={60}
                        /> */}
                        <input
                          className="form-control mb-2 col-md-6"
                          placeholder="End Time"
                          type="text"
                          id="text"
                          value={end}
                          onChange={(e) => setEnd(e.target.value)}
                          required
                          readOnly
                        ></input>
                      </div>

                      <div className="col-md-6">
                        <label className=" my-1">Field</label>
                        <input
                          className="form-control mb-2 col-md-6"
                          placeholder="Field"
                          type="text"
                          id="field"
                          value={field}
                          onChange={(e) => setField(e.target.value)}
                          required
                          readOnly
                        ></input>
                      </div>

                      <div className="col-md-6">
                        <label className=" my-1">Service</label>
                        <input
                          className="form-control mb-2 col-md-6"
                          placeholder="Service"
                          type="text"
                          id="service"
                          value={service}
                          onChange={(e) => setService(e.target.value)}
                          required
                          readOnly
                        ></input>
                      </div>

                      <div className="col-md-6">
                        <label className=" my-1">Duration</label>
                        {/*  */}
                        {serviceslug === "pitch-rental" ? (
                          <>
                                                      <select
                              className="form-control mb-2 col-md-12"
                              onChange={(e) => handleDurationChange(e.target.value)}
                              value={duration}
                              required
                            >
                              <option value="" disabled>
                                Duration
                              </option>
                              {Object.entries(selectDuration).map(
                                ([durationId, duration]) => (
                                  <option
                                    value={duration}
                                    key={`duration-id-` + durationId}
                                  >
                                    {duration} Minutes
                                  </option>
                                )
                              )}
                            </select>
                          

                          </>
                        ) : (
                          <input
                            className="form-control mb-2 col-md-6"
                            placeholder="Duration"
                            type="text"
                            id="duration"
                            value={displayduration}
                            onChange={(e) => setDuration(e.target.value)}
                            required
                            readOnly
                          ></input>
                        )}
                      </div>
                      {serviceslug === "birthday-party" ? (
                        <>
                          <div className="col-md-6">
                            <label className=" my-1">Quantity</label>
                            <input
                              className="form-control mb-2 col-md-6"
                              placeholder="Quantity"
                              type="number"
                              id="quantity"
                              min="10"
                              value={quantity}
                              // onChange={(e) => setQuantity(e.target.value)}
                              onChange={handleQuantity}
                            ></input>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="catering" className=" my-2">
                              Catering Type
                            </label>
                            <select
                              className="form-control mb-2 col-md-12"
                              value={birthdayextraservice}
                              onChange={(e) => handleCatering(e.target.value)}
                              required
                              name="catering"
                            >
                              <option value="" disabled>
                                Catering Type
                              </option>
                              {Object.entries(birthdaycost).map(
                                ([fieldId, booking]) => (
                                  <option
                                    value={booking.slug}
                                    key={`booking-cost-` + fieldId}
                                  >
                                    {booking.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="col-md-6">
                        <label className=" my-2">Booking Status</label>
                        <select
                          className="form-control mb-2 col-md-12"
                          value={booking_status}
                          onChange={(e) => setBooking_Status(e.target.value)}
                          required
                        >
                          <option value="">Booking Status</option>
                          {Object.entries(bookingstatus).map(
                            ([fieldId, booking]) => (
                              <option
                                value={booking?.slug}
                                key={`booking-` + fieldId}
                              >
                                {booking?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="col-md-6">
                        <label className=" my-2">Payment Status</label>
                        <select
                          className="form-control mb-2 col-md-12"
                          value={payment_status}
                          onChange={(e) => setPayment_Status(e.target.value)}
                          required
                        >
                          <option value="">Payment Status</option>
                          {Object.entries(paymentstatus).map(
                            ([fieldId, payment]) => (
                              <option
                                value={payment?.slug}
                                key={`paymentstatus-` + fieldId}
                              >
                                {" "}
                                {payment?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="col-md-6">
                        <label className=" my-2">Payment Type</label>
                        <select
                          className="form-control mb-2 col-md-12"
                          value={payment_type}
                          onChange={(e) => setPayment_Type(e.target.value)}
                          required
                        >
                          <option value="">Payment Type</option>
                          {Object.entries(paymenttype).map(
                            ([fieldId, payment]) => (
                              <option
                                value={payment?.slug}
                                key={`paymenttype-` + fieldId}
                              >
                                {" "}
                                {payment?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="col-md-6">
                        <label className=" my-2">Payment</label>
                        <select
                          className="form-control mb-2 col-md-12"
                          value={payment}
                          onChange={(e) => setPayment(e.target.value)}
                          required
                        >
                          <option value="">Payment</option>
                          {Object.entries(paymentt).map(
                            ([fieldId, payment]) => (
                              <option
                                value={payment.slug}
                                key={`payment-` + fieldId}
                              >
                                {" "}
                                {payment?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="col-md-6">
                        <label className=" my-2">Amount Paid</label>
                        <input
                          className="form-control mb-2 col-md-6"
                          placeholder="Amount Paid"
                          type="number"
                          id="amount"
                          value={paid}
                          onChange={(e) => setPaid(e.target.value)}
                        ></input>
                      </div>

                      <div className="col-md-12">
                        <label className=" my-2">Notes</label>
                        <textarea
                          className="form-control mb-2 col-md-6"
                          placeholder="Notes"
                          type="text"
                          id="note"
                          value={note}
                          onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  {recurring ? (
                    <div className="col-md-12 modal-body gap-3">
                      <table className="table table-hover table-responsive">
                        <thead className="bg-black text-white">
                          <tr className="">
                            <td></td>
                            <td className="checkbox">Date</td>

                            <td>Start</td>
                            <td>End</td>
                            <td>Payment Status</td>
                            <td>Actions</td>
                          </tr>
                        </thead>
                        <tbody>
                          {recurringData?.map((data, id) => (
                            // console.log(id),
                            // console.log(data),
                            <>
                              {data?.is_server_past === true ||
                              (data?.is_booking_past === true &&
                                data?.is_current === false) ? (
                                <>
                                  <tr
                                    key={"recurring-" + id}
                                    className="disbabled"
                                  >
                                    <td>
                                      {data?.payment_status?.slug ===
                                      "pending" ? (
                                        <input
                                          type="checkbox"
                                          id="recurring"
                                          className="recurringcheckbox  mt-box"
                                          name="recurring"
                                          onChange={() =>
                                            setPaymentRecurral(
                                              data?.booking_id,
                                              data
                                            )
                                          }
                                          // value={inputValue}
                                          // onChange={handleRecurring}
                                          // checked={recurring}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td>
                                      {moment(data?.booking?.start).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </td>
                                    <td>
                                      {moment(data?.booking?.start).format(
                                        "HH:mm"
                                      )}
                                    </td>
                                    <td>
                                      {moment(data?.booking?.end).format(
                                        "HH:mm"
                                      )}
                                    </td>
                                    <td>{data?.payment_status?.name}</td>
                                    <td>
                                      {/* <button
                                        className="btn btn-danger "
                                        type="button"
                                        onClick={() =>
                                          bookingDeleteRecurring(data.id, id)
                                        }
                                      >
                                        Delete
                                      </button> */}

                                      {data?.payment_status?.slug ===
                                      "pending" ? (
                                        <>
                                          <button
                                            className="btn btn-danger me-3 invisible"
                                            type="button"
                                            onClick={() =>
                                              bookingDeleteRecurring(
                                                data.id,
                                                id
                                              )
                                            }
                                            disabled
                                          >
                                            Delete
                                          </button>

                                          <button
                                            className="btn btn-dark"
                                            type="button"
                                            onClick={() =>
                                              bookingPaid(data.booking_id, id)
                                            }
                                          >
                                            Paid
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {/* <button
                                            className="btn btn-danger me-3"
                                            type="button"
                                            onClick={() =>
                                              bookingDeleteRecurring(
                                                data.id,
                                                id
                                              )
                                            }
                                            disabled
                                          >
                                            Delete
                                          </button> */}
                                          {/* <button
                                            className="btn btn-dark"
                                            type="button"
                                            onClick={() =>
                                              bookingPaid(data.booking_id, id)
                                            }
                                            disabled
                                          >
                                            Paid
                                          </button> */}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr
                                  key={"recurring-" + id}
                                  className={
                                    data?.is_current === true
                                      ? "bookingiscurrent"
                                      : ""
                                  }
                                >
                                  <td>
                                    {data?.payment_status?.slug ===
                                    "pending" ? (
                                      <input
                                        type="checkbox"
                                        id="recurring"
                                        className="recurringcheckbox mt-box"
                                        name="recurring"
                                        onChange={() =>
                                          setPaymentRecurral(
                                            data?.booking_id,
                                            data
                                          )
                                        }
                                        // value={inputValue}
                                        // onChange={handleRecurring}
                                        // checked={recurring}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  <td>
                                    {moment(data?.booking?.start).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>
                                    {moment(data?.booking?.start).format(
                                      "HH:mm"
                                    )}
                                  </td>
                                  <td>
                                    {moment(data?.booking?.end).format("HH:mm")}
                                  </td>
                                  <td>{data?.payment_status?.name}</td>
                                  <td>
                                    {data?.payment_status?.slug ===
                                    "pending" ? (
                                      <>
                                        <button
                                          className="btn btn-danger me-3"
                                          type="button"
                                          onClick={() =>
                                            bookingDeleteRecurring(data.id, id)
                                          }
                                        >
                                          Delete
                                        </button>

                                        <button
                                          className="btn btn-dark"
                                          type="button"
                                          onClick={() =>
                                            bookingPaid(data.booking_id, id)
                                          }
                                        >
                                          Paid
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        {/* <button
                                          className="btn btn-danger me-3"
                                          type="button"
                                          onClick={() =>
                                            bookingDeleteRecurring(data.id, id)
                                          }
                                        >
                                          Delete
                                        </button> */}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>
                      {/* <button
                        className="btn btn-danger "
                        type="button"
                        onClick={() => bookingDeleteAllRecurring(data.id)}
                      >
                        Delete All Reccuring
                      </button> */}
                      <div className="row">
                        <div className="col-6">
                          {paymentbutton === true ? (
                            <button
                              className="btn btn-payment me-3"
                              type="button"
                              onClick={generatePaymentLink}
                            >
                              Generate Payment Link
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6">
                          {recurrPayment ? (
                            <p
                              className="pay-url text-black mb-2 fs-18"
                              onClick={() => copyToClipboard(recurrPayment)}
                            >
                              Payment Url{" "}
                              <img
                                src={copyImage}
                                height="20px"
                                className="ps-3"
                                alt="copy "
                              />
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {serviceslug !== "birthday-party" ? (
                    <>
                      <div className="modal-body gap-3">
                        <label className="recurringLabel mb-2">
                          Recurring:{" "}
                          <input
                            type="checkbox"
                            id="recurring"
                            className="recurringcheckbox"
                            name="recurring"
                            value={inputValue}
                            onChange={handleRecurring}
                            // checked={recurring}
                          />
                        </label>
                        {/* <button
                      type="button"
                      className="btn btn-secondary mr-2 mb-2"
                      value={inputValue}
                      onClick={handleRecurring}
                    >
                      Add/Edit Recurring Booking
                    </button> */}
                        {editrecurring ? (
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <select
                                  className="form-control mb-2 col-md-6"
                                  id="field-profile-type"
                                  name="profile_type"
                                  value={period}
                                  onChange={(e) =>
                                    handleDuration(e.target.value)
                                  }
                                >
                                  <option key="plaeholder" value="">
                                    {" "}
                                    Select Type
                                  </option>
                                  <option key="daily" value="daily">
                                    Daily
                                  </option>
                                  <option key="weekly" value="weekly">
                                    Weekly
                                  </option>
                                  <option key="monthly" value="monthly">
                                    Monthly
                                  </option>
                                  <option
                                    key="custom-weekly"
                                    value="weekly-custom"
                                  >
                                    Custom Weekly
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-6">
                                <input
                                  className="form-control mb-2 col-md-6"
                                  placeholder="Enter Length"
                                  type="number"
                                  id="lenghth"
                                  value={length}
                                  // max={52}
                                  // onChange={(e) => setLength(e.target.value)}
                                  onChange={handleLength}
                                ></input>
                              </div>
                            </div>

                            {hascustomweek ? (
                              <div className="custom-weekly-items">
                                {customdates.map((item) => (
                                  // console.log(item),
                                  <div
                                    key={item.id}
                                    className={
                                      item.shouldBeChecked
                                        ? "checked d-inline-flex custom-week"
                                        : "d-inline-flex custom-week"
                                    }
                                    onClick={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  >
                                    {item.name}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}

                            <button
                              type="button"
                              className="btn btn-secondary mr-2 mt-3 mb-4"
                              value={inputValue}
                              onClick={getRecurring}
                            >
                              Get Recurring Booking
                            </button>
                            {/*  */}

                            <table className="table table-hover table-responsive">
                              <thead className="bg-black text-white">
                                <tr className="">
                                  <td>Date</td>
                                  <td>Start</td>
                                  <td>End</td>
                                  <td>Availability</td>
                                </tr>
                              </thead>
                              <tbody>
                                {recurringDataEdit.map((data, id) => (
                               
                                  <tr
                                    key={"recurring-" + id}
                                    className={
                                      data?.booking?.is_same_group === true
                                        ? "samegroup"
                                        : "unavailable" ||
                                          data?.booking === false
                                        ? ""
                                        : ""
                                    }
                                  >
                                    <td>
                                      {moment(data.start).format("YYYY-MM-DD")}
                                    </td>
                                    <td>
                                      {moment(data.start).format("HH:mm")}
                                    </td>
                                    <td>{moment(data.end).format("HH:mm")}</td>
                                    <td>
                                      {data?.booking?.is_same_group === true ? (
                                        <>Available</>
                                      ) : <>Unavailable</> ||
                                        data?.booking?.false ? (
                                        <>Available</>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* recurhasConflict */}
                  {success ? (
                    <>
                      <div className="modal-body gap-3"></div>
                      <div className="modal-footer payment-url text-bg-success">
                        <p className=" text-white mb-2  mb-2 fs-18">
                          {" "}
                          {message}
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {paymenturl ? (
                    <>
                      <div className="modal-body gap-3"></div>
                      <div className="modal-footer payment-url text-bg-success">
                        <p
                          className="pay-url  text-white mb-2 fs-18"
                          onClick={() => copyToClipboard(paymenturl)}
                        >
                          Payment Url{" "}
                          <img
                            src={copyImage}
                            height="20px"
                            className="ps-3"
                            alt="copy "
                          />
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {failure ? (
                    <>
                      <div className="modal-body gap-3"></div>
                      <div className="modal-footer payment-url text-bg-warning">
                        <p className="text-white mb-2 fs-18">
                          Could Not Update
                        </p>
                        <p className="text-white">{failuretext}</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="modal-body gap-3"></div>
                  <div className="modal-body gap-3">
                    <div className="row">
                      <div className="col">
                        {hasrecurring ? (
                          <h5 className="modal-title">
                            Amount Paid {overallpaid}
                          </h5>
                        ) : (
                          <h5 className="modal-title">Amount Paid {paid}</h5>
                        )}
                      </div>
                      <div className="col">
                        {hasrecurring ? (
                          <h5 className="modal-title">
                            Pending Amount{" "}
                            {Number(overalltotal) - Number(overallpaid)}
                          </h5>
                        ) : (
                          <h5 className="modal-title">
                            Pending Amount {Number(totalcost) - Number(paid)}
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="modal-body gap-3"></div> */}
                  <div className="modal-footer">
                    <div>
                      {hasrecurring ? (
                        <h5 className="modal-title">Total {overalltotal}</h5>
                      ) : (
                        <h5 className="modal-title">Total {totalcost}</h5>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger me-2"
                        value={inputValue}
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                      {recurring ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-danger me-2"
                            value={inputValue}
                            onClick={handleDeleteAllWithRecurring}
                          >
                            Delete All
                          </button>
                        </>
                      ) : (
                        <></>
                      )}

                      <button
                        type="button"
                        className="btn btn-outline-success me-2"
                        value={inputValue}
                        onClick={handleRefreshPaymentUrl}
                      >
                        Refresh Payment Url
                      </button>

                      <button
                        type="button"
                        className="btn btn-secondary me-2"
                        value={inputValue}
                        onClick={handleChange}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-success">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBooking;
