import React, { useState } from 'react';
import logo from "./logo.svg";
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home/Home';
import Customer from './components/Customer/Customer';

import Booking from './Booking/Booking';
import Services from './Services/Services';
import './Appcss.css';
import Footer from './components/Footer/Footer';
import AddCustomer from './components/AddCustomer/AddCustomer';
import Settings from './Settings/Settings';
import Login from './Auth/Login'
import AuthToken from "./Auth/AuthToken"
import auth from "./Auth/jwtMiddleware";
import Bookings from './components/Bookings/Bookings';
import CustomerBooking from './components/CustomerBookings/CustomerBookings';
// import axios from 'axios';
const api = process.env.REACT_APP_PUBLIC_URL;



async function logout() {
  try {
    await auth()
      .post(`${api}/auth/logout`)
      .then(({ data }) => {


      })
      .catch((e) => {
          // console.log(e)
        // this.bookingsloader = false;
      });
  } catch (error) {
      // console.log(error)
    // this.bookingsloader = false;
  }
}

const App = () => {
  const [token, setToken] = AuthToken([]);

  const userlogout = async e => {
    logout()
    localStorage.clear();
    window.location.reload(false);
    // console.log('logout')
  }



  if(!token.token || token =={}) {
    return <Login setToken={setToken} />
  }
  return (
    <>
  
    <Router>
    <div className="container MainContainer">
    <div className="d-flex justify-content-between align-items-center p-4">
      
    <Link to="/"><img src={logo} width={78} height={76} alt="All Star Sport" className="img-responsive"></img></Link>
           
    <div >
    <div className="navbar-nav d-flex">
     
    <nav className="navbar navbar-expand-lg navbar-light  p-4 custom-header ">
          <ul className='navbar-nav gap-3 pl-4'>
            <li className='nav-item active'>
              <Link to="/">Home</Link>
            </li>
          
            <li className='nav-item'>
              <Link to="/customer">Customers</Link>
            </li>
            {/* <li className='nav-item'>
              <Link to="/services">Services</Link>
            </li> */}
            <li className='nav-item'>
              <Link to="/booking">Bookings</Link>
            </li>
            {/* <li className='nav-item'>
              <Link to="/settings">Settings</Link>
            </li> */}
            <li className='nav-item'>
              <Link to='/' onClick={userlogout}>Logout</Link>
            </li>
         
          </ul>
        </nav>

        </div>   
      </div>
      </div>
      <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Booking />} />
          <Route path="/login" element={<Login />} />
        
          <Route path="/customer" element={<Customer />} />
          <Route path="/services" element={<Services />} />
          <Route path="/booking" element={<Bookings />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/addcustomer" element={<AddCustomer />} />
          <Route path="/customerbooking" element={<CustomerBooking />} />
          <Route path="/customerbooking/:id" element={<CustomerBooking />} />
        
        </Routes>
      </div>
    </Router>
    <Footer/>
    </>
  );
};

export default App;
