import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Login.css";
import axios from "axios";
import loader from "../assets/images/loader.svg";
import logo from  "../logo.svg"

//
//
const api = process.env.REACT_APP_PUBLIC_URL;

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [errortext,setErroText] = useState('');
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    loginUser({
      email: username,
      password: password,
    });
  };

  async function loginUser(credentials) {
    setLoading(true)
    setError(false);
    try {
      await axios
        .post(`${api}/auth/login`, credentials)
        .then(({ data }) => {
          if (data?.status === 200) {
            localStorage.setItem("authtoken", data?.data.token);
            // setLoading(false)
            // setToken(data?.token);
            window.location.reload(false);
          } else {
            setErroText(data?.message)
            setError(true);
            setLoading(false)
           
          }
        })
        .catch((e) => {
          // console.log(e);
          setErroText(e)
          // this.bookingsloader = false;
          setLoading(false)
        });
    } catch (error) {
      // console.log(error);
      // this.bookingsloader = false;
    }
  }

  return (

    <>

      {loading ? (
        <>
          <div id="loader">
            <img src={loader} width="100" alt="Loader" />
          </div>
        </>
      ) : (
        <></>
      )}
    
    <div className="login-wrapper">
      <div className="login-form">
        <div className="d-flex justify-content-center align-content-center pb-5">
        <img src={logo} width="100" alt="Loader" />
        </div>
     
        <h1>Please Log In</h1>
        <form className="d-flex flex-column" onSubmit={handleSubmit}>
          <label className="pb-4">
            <p className="pb-2">Email</p>
            <input
              type="email"
              className="w-100"
              onChange={(e) => setUserName(e.target.value)}
            />
          </label>
          <label className="pb-4">
            <p className="pb-2">Password</p>
            <input
              type="password"
              className="w-100"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>

          {error ? (
            <>
              <div className="modal-body gap-3"></div>
              <div className="modal-footer payment-url text-bg-danger">
                <p className="text-white mb-2 fs-18 text-center py-2">{errortext}</p>
                {/* <p className="text-white">{failuretext}</p> */}
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="w-100">
            <button type="submit" className=" w-100 submit-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func,
};
