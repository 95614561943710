import axios from 'axios';

const axiosWithAuth = () => {
  const instance = axios.create();

  // Request interceptor
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('authtoken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers.Accept ="*"
      // config.headers['Content-Type'] = 'application/json'
      // config.headers['Access-Control-Allow-Origin'] = "*"
    }
    return config;
  });

  return instance;
};

export default axiosWithAuth;